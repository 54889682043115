import { applyMiddleware, createStore, Middleware, Store } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';

import promisify from '@skillchill/redux-promisify';

import storageMiddleware from '@/store/middleware/localStorage';
import initializeState from '@/store/middleware/localStorage/initializeState';
import { PathList } from '@/store/middleware/localStorage/types';
import rootReducer from '@/store/reducers';

const cookiesPath: PathList = [{ path: 'Session.rememberMe' }];

export default function configureStore(): Store {
	const middleware: Middleware[] = [promisify(), storageMiddleware(cookiesPath)];

	const state = initializeState(cookiesPath);

	return createStore(rootReducer, state, composeWithDevTools(applyMiddleware(...middleware)));
}

import { useSnackbar } from 'notistack';
import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import { EIssueDirection } from '@skillandchill/tasker-types';
import { EKeys } from '@skillandchill/tasker-types/dist/types/common/keys';
import { errorVariant } from '@skillandchill/tasker-widgets2/dist/utils';

import { issue_fetchNext } from '@/store/actions';
import { Dispatch, IReducerState } from '@/store/types';
import { useQuery } from '@/utils/hooks/useQuery';
import { useTrans } from '@/utils/hooks/useTrans';

import { Props } from './model';
import { Resources } from './resources';

export const KeyboardSupport = (props: Props) => {
	const { handleClose } = props;
	const { t } = useTrans('IssueModal');
	const dispatch: Dispatch = useDispatch();
	const history = useHistory();
	const query = useQuery();
	const issueId = useMemo(() => parseInt(query['issueId']), [query['issueId']]);
	const snackBar = useSnackbar();

	const key: EKeys = useSelector((state: IReducerState) => state?.Key?.key);
	const actionNumber: number = useSelector((state: IReducerState) => state?.Key?.actionNumber);

	useEffect(() => {
		if (actionNumber <= 0 || !history.location.search.includes(Resources.issuePathSingle)) return;
		if (key === EKeys.Escape && handleClose) handleClose();
		if (key === EKeys.ArrowRight) goTo(EIssueDirection.Next);
		if (key === EKeys.ArrowLeft) goTo(EIssueDirection.Previous);
	}, [actionNumber]);

	const goTo = (data: EIssueDirection) => {
		if (!issueId || Number.isNaN(issueId)) return;
		dispatch(issue_fetchNext(issueId, data)).then(x => {
			if (x?.data) {
				const newIssueId = Number.parseInt(x?.data);
				if (newIssueId > 0) history.replace(Resources.issuePath(x?.data));
				if (newIssueId === -1) snackBar.enqueueSnackbar(t('limit'), errorVariant);
			}
		});
	};
};

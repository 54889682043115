import { Typography } from '@mui/material';

import { Props } from './model';
import { useStyles } from './styles';

export const TimeColumn = (props: Props) => {
	const { title, time } = props;
	const classes = useStyles();

	return (
		<div className={classes.flexRow}>
			<Typography className={classes.title}>{title}</Typography>
			<Typography className={classes.time}>{time}</Typography>
		</div>
	);
};

import { makeStyles } from '@mui/styles';

import { getGlobalStyles } from '@skillandchill/tasker-widgets2/dist/utils/assets/GlobalStyles/styles';
import { CustomTheme } from '@skillandchill/tasker-widgets2/dist/utils/theme';

export const useStyles = makeStyles(
	(theme: CustomTheme) => {
		return {
			...getGlobalStyles(),
			title: {
				fontWeight: 'bold',
				marginBottom: theme.spacing(0.5),
				marginRight: theme.spacing(1),
			},
			time: { fontSize: theme.spacing(2) },
		};
	},
	{ name: `IssueModal_IssueModalContent_IssueDetailsPage_RightSideIssueData_ExtraIssueInfo_TimeInfo_TimeColumn` }
);

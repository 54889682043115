import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
};

export const Translation = {
	pl: {
		OrganizationPanel: {
			TabsContent: {
				Preferences: {
					Days: {
						label: 'Ilość dni dla użytkownika na uzupełnienie ewidencji',
						tooltip:
							'Przy potrzebie zmian w zaakceptowanym okresie tyle dni będzie przysługiwało użytkownikowi. (Po zaakceptowaniu przez przełożonego prośby)',
					},
					Session: {
						label: 'Ile minut ma trwać sesja?',
						tooltip: 'Po jakim czasie(w minutach) sesja ma zostać unieważniona?',
					},
				},
			},
		},
	},
	en: {
		OrganizationPanel: {
			TabsContent: {
				Preferences: {
					Days: {
						label: 'Days for editing records',
						tooltip:
							'If changes are needed within the accepted period, this is how many days the user will be entitled to.(After accepting the request by supervisor)',
					},
					Session: {
						label: 'Session duration',
						tooltip: 'When session(in minutes) should deactivate?',
					},
				},
			},
		},
	},
};

import clsx from 'clsx';
import { useContext } from 'react';

import { Typography } from '@mui/material';

import { EFieldDefinitionType, EInputTypes } from '@skillandchill/tasker-types';
import { CustomInput } from '@skillandchill/tasker-widgets2';

import { IssueFormSectionsWidgetContext } from '../..';
import { IssuePropertyTitle } from '../IssuePropertyTitle';
import { useInitialAttributeDefinitionValues } from '../utils/useInitialAttributeDefinitionValues';

import { Props } from './model';
import { useStyles } from './styles';

export const TextIssueProperty = (props: Props) => {
	const { issueFormSectionToIssueField, inEdit } = props;
	const classes = useStyles();

	const { handleChange, state } = useContext(IssueFormSectionsWidgetContext);

	const initial = useInitialAttributeDefinitionValues(issueFormSectionToIssueField);

	const initialInputValue = initial?.length ? (initial?.[0]?.ValueText as string) : '';

	const inputValue =
		state &&
		(((state?.[EFieldDefinitionType.Text] ?? {})[issueFormSectionToIssueField?.AttributeDefinitionId as number] ??
			initialInputValue) as string);

	return (
		<div className={clsx(classes.flexYCenter, classes.container)}>
			<IssuePropertyTitle {...props} />
			<div className={clsx(classes.flexGrow, classes.textContainer)}>
				<CustomInput
					type={EInputTypes.text}
					label=""
					handleChange={val => handleChange && handleChange(val as string, issueFormSectionToIssueField)}
					value={inputValue}
					inEdit={inEdit}
					canSetInputInNonEdit
					hideErrorMessage
					nonEditComponent={<Typography className={classes.valueContainer}>{initialInputValue}</Typography>}
				/>
			</div>
		</div>
	);
};

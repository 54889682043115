import clsx from 'clsx';
import React, { SyntheticEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { useDispatch, useSelector } from 'react-redux';
import { Virtuoso } from 'react-virtuoso';

import ReorderIcon from '@mui/icons-material/Reorder';
import SaveRoundedIcon from '@mui/icons-material/SaveRounded';
import {
	Card,
	CardHeader,
	Divider,
	IconButton,
	LinearProgress,
	List,
	Popover,
	Tab,
	Tabs,
	Tooltip,
	Typography,
} from '@mui/material';

import { EWorklogIssues, IIssue, IPage, IWorkLog } from '@skillandchill/tasker-types';
import { Pagination } from '@skillandchill/tasker-widgets2';
import { useControlContext } from '@skillandchill/tasker-widgets2/dist/utils/controlContext';
import { VisibilityControl } from '@skillandchill/tasker-widgets2/dist/widgets/VisibilityControl';

import { Reason } from '@/layouts/Dashboard/WorklogStarter/WorklogStarterPopover/model';
import { get_searchWorkableIssues, issue_fetchWorkable, set_favourite_issues_order } from '@/store/actions';
import { IReducerState } from '@/store/reducers/types';
import { Dispatch } from '@/store/types';
import { rowsPerPage } from '@/utils/configuration';
import { useToggle } from '@/utils/hooks/useToggle';
import { useTrans } from '@/utils/hooks/useTrans';
import { getFirst } from '@/utils/utils';
import { HeightPreservingItem } from '@/views/KanbanBoard/KanbanContent/ColumnsKanbanContent/Swimlane/TaskList/HeightPreservingItem';

import { Search } from 'view/SearchBar/Search';

import { Props } from '../model';
import { WorklogTile } from '../WorklogTile';

import { DraggableWorklogTile } from './DraggableWorklog/DraggableWorklogTile';
import { getTabs, Resources } from './resources';
import { useStyles } from './styles';

export const WorklogStarterPopover = (props: Props): JSX.Element => {
	const {
		onStartWorklog,
		onFinishWorklog,
		onPauseWorklog,
		onClose,
		open,
		handleFetchWorkableIssues,
		isLoading,
		openWorkLogStarter,
		...otherProps
	} = props;
	const classes = useStyles();
	const { tString } = useTrans('Dashboard.WorklogStarter.WorklogStarterPopover');
	const activeIssueControlContext = useControlContext();
	const dispatch: Dispatch = useDispatch();

	const languageCode = useSelector((state: IReducerState) => state?.Session?.language);
	const workableIssuesCount: number | undefined = useSelector(
		(state: IReducerState) => state?.DashBoard?.workableIssuesCount
	);
	const isMobile: boolean = useSelector((state: IReducerState) => state?.Session?.isMobile);
	const activeWorklogs: IWorkLog[] = useSelector((state: IReducerState) => state?.DashBoard?.activeWorklogs);
	const issues: IIssue[] = useSelector((state: IReducerState) => state?.DashBoard?.workableIssue);

	const searchWorkableIssues: IPage<IIssue[]> = useSelector(
		(state: IReducerState) => state?.DashBoard?.SearchWorkableIssues
	);
	const [count] = useState(9);
	const [searchCount] = useState(rowsPerPage);
	const [searchPage, setSearchPage] = useState(0);
	const [searchValue, setSearchValue] = useState(Resources.GlobalResources.empty);
	const [from, setFrom] = useState(6);
	const [ownIsLoading, setOwnIsLoading] = useState<boolean>(false);
	const [reorderMode, onReorderMode, offReorderMode] = useToggle(false);
	const [tab, setTab] = useState<string>(EWorklogIssues.Favourites);
	const [items, setItems] = useState(issues);

	useEffect(() => {
		setItems(issues);
	}, [issues]);

	useEffect(() => {
		offReorderMode();
		if (!!openWorkLogStarter && openWorkLogStarter) firstFetch();
	}, [openWorkLogStarter]);

	useEffect(() => {
		firstFetch();
		if (searchValue) dispatch(get_searchWorkableIssues(searchValue, searchPage * searchCount, searchCount, tab));
	}, [tab, activeWorklogs]);

	useEffect(() => {
		if (isLoading != ownIsLoading) setOwnIsLoading(isLoading ?? false);
	}, [isLoading]);

	useEffect(() => {
		if (issues?.length == 0 && workableIssuesCount == undefined) {
			firstFetch();
		}
	}, [issues]);

	useEffect(() => {
		if (!!searchValue && searchValue != Resources.GlobalResources.empty)
			dispatch(get_searchWorkableIssues(searchValue, searchPage * searchCount, searchCount, tab));
		else dispatch(issue_fetchWorkable(searchPage * searchCount, searchCount, tab));
	}, [searchPage]);

	const handleFetchMore = () => {
		if (
			!ownIsLoading &&
			handleFetchWorkableIssues &&
			workableIssuesCount &&
			workableIssuesCount > issues?.length &&
			from < workableIssuesCount &&
			open
		) {
			setOwnIsLoading(true);
			handleFetchWorkableIssues(from, count, tab);
			setFrom(from + count);
		} else setOwnIsLoading(false);
	};

	const handleStartIssue = (id: number) => {
		if (onStartWorklog) return onStartWorklog(id);
		return Promise.resolve();
	};
	const handlePauseWorklog = (id: number) => {
		if (onPauseWorklog) return onPauseWorklog(id);
		return Promise.resolve();
	};
	const handleFinishWorklog = (id: number) => {
		if (onFinishWorklog) return onFinishWorklog(id);
		return Promise.resolve();
	};

	const handleClose = (event: unknown, reason: Reason) => {
		setSearchValue(Resources.GlobalResources.empty);
		if (onClose) onClose(event, reason);
		activeIssueControlContext.removeAllListeners(Resources.comment);
	};

	const handleEditMode = () => {
		if (!reorderMode) onReorderMode();
		else {
			dispatch(set_favourite_issues_order(items));
			offReorderMode();
		}
	};

	const tabs = useMemo<{ value: string; label: string }[]>(() => {
		return getTabs();
	}, [languageCode]);

	const handleTabsChange = (event: SyntheticEvent, value: any) => {
		setTab(value);
	};

	const handleSearch = (value: string) => {
		setSearchValue(value);
		dispatch(get_searchWorkableIssues(value, searchPage * searchCount, searchCount, tab));
	};

	const handleSearchClear = () => {
		setSearchValue(Resources.GlobalResources.empty);
	};

	function firstFetch() {
		if (handleFetchWorkableIssues && open) {
			handleFetchWorkableIssues(0, 15, tab);
			setFrom(15);
		}
	}

	function reorder(list: IIssue[], startIndex: number, endIndex: number): IIssue[] {
		const result = Array.from(list);
		const [removed] = result?.splice(startIndex, 1);
		result.splice(endIndex, 0, removed);
		return result?.map((item, index) => ({
			...item,
			FavouriteIssues: [{ ...(item?.FavouriteIssues ?? [])[0], Order: index }],
		}));
	}

	const onDragEnd = useCallback(
		result => {
			if (!result.destination) {
				return;
			}
			if (result.source.index === result.destination.index) {
				return;
			}
			setItems(items => reorder(items, result.source.index, result.destination.index));
		},
		[setItems]
	);

	const Item = ({ provided, item, isDragging }: any) => {
		return (
			<DraggableWorklogTile
				issue={item as IIssue}
				provided={provided}
				isMobile={isMobile}
				isDragging={isDragging}
				handleOnStart={() => handleStartIssue(item)}
				isActive={false}
			/>
		);
	};

	return (
		<Popover
			open={open ?? false}
			onClose={handleClose}
			className={classes.popover}
			anchorOrigin={{
				vertical: Resources.GlobalResources.bottom,
				horizontal: Resources.GlobalResources.left,
			}}
			PaperProps={isMobile ? Resources.fullWidth : {}}
			{...otherProps}
		>
			<div
				className={clsx(
					classes.flexColumn,
					classes.flexCrossAxisStretch,
					classes.defaultCursor,
					classes.displayAll
				)}
			>
				<div className={classes.minWidth}>
					<List disablePadding>
						<Card>
							<CardHeader
								className={clsx(classes.defaultCursor, classes.paddingBottom)}
								title={<Typography>{tString('yourIssues')}</Typography>}
							/>
							<div className={classes.flex}>
								<VisibilityControl condition={!isMobile}>
									<div className={classes.buttonPosition}>
										<VisibilityControl condition={tab == EWorklogIssues.Favourites}>
											<IconButton
												size={Resources.GlobalResources.small}
												color={Resources.GlobalResources.inherit}
												onClick={handleEditMode}
											>
												<span className={classes.flexRow}>
													<VisibilityControl
														alternative={
															<Tooltip title={tString('reorder')}>
																<ReorderIcon />
															</Tooltip>
														}
														condition={reorderMode}
													>
														<Tooltip title={tString('save')}>
															<SaveRoundedIcon
																color={Resources.GlobalResources.primary}
															/>
														</Tooltip>
													</VisibilityControl>
												</span>
											</IconButton>
										</VisibilityControl>
									</div>
								</VisibilityControl>

								<Tabs
									centered
									className={classes.fullWidth}
									onChange={handleTabsChange}
									value={tab}
									indicatorColor={Resources.GlobalResources.primary}
								>
									{tabs?.map(singleTab => (
										<Tab
											style={{ fontSize: 12 }}
											key={singleTab?.label}
											className={classes.tab}
											label={singleTab?.label}
											value={singleTab?.value}
										/>
									))}
								</Tabs>
							</div>
							<Divider />
							<div className={classes.searchSection}>
								<Search
									className={classes.search}
									onSearch={handleSearch}
									placeholder={tString('searchIssues')}
									onClearFilters={handleSearchClear}
								/>
							</div>
							<div className={clsx(classes.loadingBar, classes.fullWidth)}>
								<VisibilityControl condition={ownIsLoading}>
									<LinearProgress />
								</VisibilityControl>
							</div>
							<div
								className={clsx(
									isMobile ? classes.listLongMobile : classes.listLong,
									classes.overflowAuto
								)}
							>
								<VisibilityControl
									condition={!searchValue || searchValue === Resources.GlobalResources.empty}
									alternative={
										<div>
											{searchWorkableIssues?.Data?.map(item => {
												return (
													<>
														<WorklogTile
															issue={item}
															key={`${Resources.activeIssueKey}${item?.Id}`}
															open={false}
															fullWidth={isMobile}
															active={
																!!(
																	item.Worklog &&
																	item.Worklog.length > 0 &&
																	item.Worklog[0].IsActive
																)
															}
															handleOnStart={() => handleStartIssue(item?.Id)}
															handleOnPause={() => handlePauseWorklog(item?.Id)}
															handleOnFinish={() => handleFinishWorklog(item?.Id)}
														/>
													</>
												);
											})}
											<div className={classes.pagination}>
												<Pagination
													list={searchWorkableIssues?.Data}
													listCount={searchWorkableIssues?.Count}
													currentPage={searchPage + 1}
													rowsPerPage={searchCount}
													onPageChangeCallBack={selected => setSearchPage(selected - 1)}
												/>
											</div>
										</div>
									}
								>
									<VisibilityControl
										condition={reorderMode && tab == EWorklogIssues.Favourites}
										alternative={
											<Virtuoso
												data={issues}
												fixedItemHeight={60}
												itemSize={() => 60}
												totalCount={workableIssuesCount}
												overscan={300}
												endReached={handleFetchMore}
												itemContent={(index, item: IIssue) => {
													const isActive = getFirst(item?.Worklog ?? [])?.IsActive ?? false;
													return (
														<div>
															<WorklogTile
																open={false}
																active={isActive}
																fullWidth={isMobile}
																issue={item as IIssue}
																handleOnStart={() => handleStartIssue(item?.Id)}
																handleOnPause={() => handlePauseWorklog(item?.Id)}
																handleOnFinish={() => handleFinishWorklog(item?.Id)}
															/>
														</div>
													);
												}}
											/>
										}
									>
										<DragDropContext onDragEnd={onDragEnd}>
											<Droppable
												droppableId={Resources.droppable}
												mode={Resources.virtual}
												renderClone={(provided, snapshot, rubric) => (
													<Item
														provided={provided}
														isDragging={snapshot.isDragging}
														item={items[rubric.source.index]}
													/>
												)}
											>
												{provided => {
													return (
														<Virtuoso
															endReached={
																workableIssuesCount ? handleFetchMore : undefined
															}
															overscan={300}
															totalCount={workableIssuesCount}
															scrollerRef={
																(provided.innerRef as unknown) as (
																	ref: HTMLElement | Window | null
																) => any
															}
															fixedItemHeight={60}
															itemSize={() => 60}
															style={{ width: 500, height: 510 }}
															components={{ Item: HeightPreservingItem }}
															data={items}
															itemContent={(index, item) => {
																return (
																	<Draggable
																		draggableId={`${item?.Id}`}
																		index={index}
																		key={`${item?.Id}`}
																	>
																		{provided => (
																			<Item
																				style={Resources.tileHeight}
																				provided={provided}
																				item={item}
																				isDragging={false}
																			/>
																		)}
																	</Draggable>
																);
															}}
														/>
													);
												}}
											</Droppable>
										</DragDropContext>
									</VisibilityControl>

									{/*<VisibilityControl condition={tab == EWorklogIssues.Active}>*/}
									{/*	{activeWorklogs?.map(item => (*/}
									{/*		<WorklogTile*/}
									{/*			key={Resources.activeKey(item?.Id)}*/}
									{/*			open={false}*/}
									{/*			active={true}*/}
									{/*			fullWidth={isMobile}*/}
									{/*			issue={item?.Issue as IIssue}*/}
									{/*			handleOnStart={() => handleStartIssue(item?.Id)}*/}
									{/*			handleOnPause={() => handlePauseWorklog(item?.Id)}*/}
									{/*			handleOnFinish={() => handleFinishWorklog(item?.Id)}*/}
									{/*		/>*/}
									{/*	))}*/}
									{/*</VisibilityControl>*/}
								</VisibilityControl>
							</div>
						</Card>
					</List>
				</div>
				<Divider />
			</div>
		</Popover>
	);
};

import clsx from 'clsx';
import { useSelector } from 'react-redux';

import CloseIcon from '@mui/icons-material/Close';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import RestoreIcon from '@mui/icons-material/Restore';
import SaveIcon from '@mui/icons-material/Save';
import SaveAsIcon from '@mui/icons-material/SaveAs';

import { Button, VisibilityControl } from '@skillandchill/tasker-widgets2';

import { IReducerState } from '@/store/reducers/types';
import { useToggle } from '@/utils/hooks/useToggle';
import { useTrans } from '@/utils/hooks/useTrans';

import { FieldsRequiredHelperText } from './FieldsRequiredIndicator';
import { Props } from './model';
import { Resources } from './resources';
import { useStyles } from './styles';

export const IssueModalActions = (props: Props) => {
	const { handleSave, handleCloseModal, handleDeleteIssueWithConfirmation, handleRestore } = props;
	const classes = useStyles();
	const { t } = useTrans('IssueModal.IssueModalActions');

	const issue = useSelector((state: IReducerState) => state?.IssueModalEditor?.issueModal);

	const [canSave, allowSave, disAllowSave] = useToggle(true);

	const handleSaveWrapper = (closeModal = true) => {
		disAllowSave();
		handleSave(closeModal).finally(() => allowSave());
	};

	return (
		<div className={clsx(classes.flexYCenter, classes.container, classes.contentSpaceBetween)}>
			<div className={classes.flexYCenter}>
				<FieldsRequiredHelperText />
			</div>
			<div className={clsx(classes.buttonsContainer, classes.flexYCenter)}>
				<Button disabled={!canSave} onClick={handleCloseModal}>
					<CloseIcon />
					{t('close')}
				</Button>
				<VisibilityControl condition={!!issue?.Id}>
					<VisibilityControl
						condition={!!issue?.IsDeleted}
						alternative={
							<Button onClick={handleDeleteIssueWithConfirmation} className={classes.errorButton}>
								<DeleteForeverIcon />
								{t('delete')}
							</Button>
						}
					>
						<Button onClick={handleRestore} className={classes.restoreButton}>
							<RestoreIcon />
							{t('restore')}
						</Button>
					</VisibilityControl>
				</VisibilityControl>
				<Button
					disabled={!canSave}
					variant={Resources.GlobalResources.contained}
					onClick={() => handleSaveWrapper(false)}
				>
					<SaveAsIcon />
					{t('save')}
				</Button>
				<Button
					disabled={!canSave}
					variant={Resources.GlobalResources.contained}
					onClick={() => handleSaveWrapper(true)}
				>
					<SaveIcon />
					{t('saveAndClose')}
				</Button>
			</div>
		</div>
	);
};

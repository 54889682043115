import clsx from 'clsx';
import { useContext, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';

import AddIcon from '@mui/icons-material/Add';
import { Tooltip, Typography } from '@mui/material';

import { EFieldDefinitionType, IIssueKanbanDetails, ITag } from '@skillandchill/tasker-types';
import { CustomSelect, TaskerTag, VisibilityControl } from '@skillandchill/tasker-widgets2';

import { IReducerState } from '@/store/reducers/types';
import { getContrastTagTextColor } from '@/utils/contrastColor';
import { useQuery } from '@/utils/hooks/useQuery';

import { IssueFormSectionsWidgetContext } from '../..';
import { IssuePropertyTitle } from '../IssuePropertyTitle';
import { useInitialAttributeDefinitionValues } from '../utils/useInitialAttributeDefinitionValues';

import { Props } from './model';
import { MultiSelectValueLabelCustom } from './MultiSelectValueLabelCustom';
import { CustomOption } from './Option';
import { Resources } from './resources';
import { SingleValueCustom } from './SingleValueCustom';
import { useStyles } from './styles';
import { ValueContainer } from './ValueContainer';

export const TagGroupIssueProperty = (props: Props) => {
	const { issueFormSectionToIssueField, inEdit } = props;
	const classes = useStyles();
	const query = useQuery();

	const issueId = useMemo(() => parseInt(query['issueId']), [query['issueId']]);

	const isEdittingIssue = useMemo(() => !(!Number.isInteger(issueId) || Number.isNaN(issueId) || !issueId), [
		issueId,
	]);

	const { handleChange, state } = useContext(IssueFormSectionsWidgetContext);

	const kanbanDetails: IIssueKanbanDetails = useSelector(
		(state: IReducerState) => state?.IssueModalEditor?.issueModalKanbanDetails
	);
	const isDarkMode: boolean = useSelector((state: IReducerState) => state?.Session?.darkMode);

	const isMultiValue = issueFormSectionToIssueField?.AttributeDefinition?.IsMultiValue;

	const initial = useInitialAttributeDefinitionValues(issueFormSectionToIssueField);

	const initialTags = initial?.map(fieldValue => fieldValue?.ValueTag)?.filter(tag => tag?.Id) as ITag[];

	const selectValues =
		state &&
		((state[EFieldDefinitionType.TagGroup] ?? {})[issueFormSectionToIssueField?.AttributeDefinitionId as number] as
			| ITag[]
			| ITag
			| undefined);

	const canClearSelect = !issueFormSectionToIssueField?.AttributeDefinition?.IsMandatory;

	const getValue = () => {
		if (selectValues) return selectValues;
		else {
			if (isMultiValue) return initialTags ?? [];
			else return (initialTags?.length ?? 0) > 0 ? initialTags[0] : undefined;
		}
	};

	const handleTagsChangeV3 = (newTags: ITag[] | ITag) => {
		if (!handleChange) return;

		handleChange(newTags, issueFormSectionToIssueField);
	};

	useEffect(() => {
		if (issueFormSectionToIssueField.AttributeDefinition?.IsStatus) {
			if (kanbanDetails?.isKanban && !isEdittingIssue) {
				const newTag: ITag | undefined = kanbanDetails?.mappedTags?.find(find => {
					return (
						find.FieldDefinitionId == issueFormSectionToIssueField.AttributeDefinitionId &&
						find.TagId == kanbanDetails.columnTagId
					);
				})?.FieldDefinitionTag;

				if (!newTag) return;

				handleTagsChangeV3(newTag as ITag);
			}
		}
	}, []);

	const getCustomComponents = () => {
		if (issueFormSectionToIssueField?.AttributeDefinition?.IsMultiValue) {
			return {
				ValueContainer: ValueContainer,
				MultiValueLabel: MultiSelectValueLabelCustom,
				Option: CustomOption,
			};
		} else {
			return {
				SingleValue: SingleValueCustom,
				Option: CustomOption,
			};
		}
	};

	return (
		<div className={clsx(classes.flexYCenter, classes.root)}>
			<IssuePropertyTitle {...props} />
			<div className={clsx(classes.flexGrow, classes.selectContainer)}>
				<CustomSelect
					canSetInputInNonEdit
					inEdit={inEdit}
					isDarkMode={isDarkMode}
					label=""
					className={clsx(
						!!issueFormSectionToIssueField?.AttributeDefinition?.IsMultiValue && classes.multiValueContainer
					)}
					items={(issueFormSectionToIssueField?.AttributeDefinition?.TagGroup?.Tag as ITag[]) ?? []}
					getName={(tag: ITag) => tag?.Label ?? ''}
					getKey={(tag: ITag) => tag?.Id}
					values={getValue()}
					onChange={handleTagsChangeV3}
					args={{
						['components']: {
							...getCustomComponents(),
						},
						valuesLimit: 1,
						menuPosition: Resources.fixed,
						isClearable: canClearSelect,
						isSearchable: false,
						closeMenuOnSelect: !issueFormSectionToIssueField?.AttributeDefinition?.IsMultiValue,
					}}
					isMulti={!!issueFormSectionToIssueField?.AttributeDefinition?.IsMultiValue}
					nonEditComponent={
						<div className={clsx(classes.flexYCenter, classes.valueContainer)}>
							<VisibilityControl
								condition={!!initialTags?.length}
								alternative={<div className={clsx(classes.tag)}>-</div>}
							>
								{[...initialTags]?.slice(0, 2)?.map(initialTag => (
									<TaskerTag key={initialTag?.Id} tag={initialTag} size="small" />
								))}
								<VisibilityControl condition={initialTags?.length > 2}>
									<Tooltip
										title={[...initialTags]?.slice(2)?.map(initialTag => (
											<TaskerTag key={initialTag?.Id} tag={initialTag} size="small" />
										))}
									>
										<div className={clsx(classes.flexYCenter, classes.counter)}>
											<AddIcon className={classes.icon} />
											<Typography className={classes.counterText}>
												{initialTags?.length - 2}
											</Typography>
										</div>
									</Tooltip>
								</VisibilityControl>
							</VisibilityControl>
						</div>
					}
				/>
			</div>
		</div>
	);
};

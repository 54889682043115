import { t } from 'i18next';

import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	caption: 'caption' as const,
	warning: 'warning' as const,
	errors: {
		noSwimlanes: 'NoSwimlanes',
	},
	...GlobalResources,
};

export const emptyKanban = {
	Name: '',
	ProjectIds: [],
	KanbanColumnWrapper: {
		Label: '',
		IsImmutable: true,
		TagGroupContextId: 12,
		MultipleAllowed: true,
		Tag: [],
	},
};

export const Translation = {
	pl: {
		Kanban: {
			KanbanBoard: {
				KanbanCreator: {
					createKanbanError: 'Wystąpił błąd podczas tworzenia kanbana. Spróbuj ponownie.',
					popupLabel: 'Kreator kanbana',
					close: 'Zamknij',
					goBack: 'Idź do poprzedniego kroku',
					goBackToCreator: 'Wróć do kreatora',
					back: 'Wróć',
					goForward: 'Idź do następnego kroku',
					next: 'Dalej',
					step: 'Krok',
					alreadyCompleted: 'jest już zakończony',
					finish: 'Zakończ',
					completeStep: 'Zakończ krok',
					createKanban: 'Stwórz Kanban',
				},
			},
		},
	},
	en: {
		Kanban: {
			KanbanBoard: {
				KanbanCreator: {
					createKanbanError: 'An error occurred while creating kanban. Please try again.',
					popupLabel: 'Kanban creator',
					close: 'Close',
					goBack: 'Go to previous step',
					goBackToCreator: 'Go back to creator',
					back: 'Back',
					goForward: 'Go to next step',
					next: 'Next',
					step: 'Step',
					alreadyCompleted: 'already completed',
					finish: 'Finish',
					completeStep: 'Complete Step',
					createKanban: 'Create Kanban',
				},
			},
		},
	},
};

export const getValidationRules = (): any => {
	return {
		Name: {
			presence: {
				allowEmpty: false,
				message: t('Kanban.KanbanBoard.KanbanCreator.CreationPopupContent.Errors.nameErrorMessage'),
			},
		},
		ProjectIds: {
			presence: {
				allowEmpty: false,
				message: t('Kanban.KanbanBoard.KanbanCreator.CreationPopupContent.Errors.projectsErrorMessage'),
			},
		},
		'KanbanColumnWrapper.Label': {
			presence: {
				allowEmpty: false,
				message: t('Kanban.KanbanBoard.KanbanCreator.CreationPopupContent.Errors.tagGroupLabelErrorMessage'),
			},
		},
	};
};

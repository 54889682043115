import { AsyncAction } from '@skillchill/redux-promisify';

import {
	IPermission,
	IRole,
	ISetRoles,
	ITaskerObject,
	ITaskerUserProjectsAndRoles,
	ITaskerUserRole,
} from '@skillandchill/tasker-types';

import { session_loginWithToken } from '@/store/actions/sessionActions';
import { IJson } from '@/store/ITypes';
import axios from '@/utils/axios';

export const ROLE_FETCH_BY_PROFILE = 'ROLE_FETCH_BY_PROFILE';
export const FETCH_ALL_PERMISSIONS_NEW = 'FETCH_ALL_PERMISSIONS_NEW';
export const FETCH_ALL_ROLES_NEW = 'FETCH_ALL_ROLES_NEW';
export const FETCH_ALL_ROLE_USERS = 'FETCH_ALL_ROLE_USERS';
export const FETCH_ALL_ROLES_BY_USER_NEW = 'FETCH_ALL_ROLES_BY_PROFILE_NEW';
export const FETCH_ROLE_PERMISSIONS = 'FETCH_ROLE_PERMISSIONS';
export const UPDATE_PERMISSIONS = 'UPDATE_PERMISSIONS';

const basePath = 'TaskerUserRole';

export type IPermissionTmp = IPermission & { PermissionToRoleId: number };

function _receiveFetchRolesByProfile(roles: ITaskerUserRole[]) {
	return {
		type: ROLE_FETCH_BY_PROFILE,
		roles,
	};
}

function _updatePermissions(permissions: ITaskerObject[][]) {
	return {
		type: UPDATE_PERMISSIONS,
		permissions,
	};
}

function _fetchAllPermissions(permissions: ITaskerObject[]) {
	return {
		type: FETCH_ALL_PERMISSIONS_NEW,
		permissions,
	};
}

export function _fetchAllRoles(roles: IRole[]) {
	return {
		type: FETCH_ALL_ROLES_NEW,
		roles,
	};
}

function _fetchRolesByUser(rolesById: ITaskerUserRole[]) {
	return {
		type: FETCH_ALL_ROLES_BY_USER_NEW,
		rolesById,
	};
}

function _fetchAllUsers(taskerUsers: ITaskerUserProjectsAndRoles[]) {
	return {
		type: FETCH_ALL_ROLE_USERS,
		taskerUsers,
	};
}
//////////////////////////////////////////////////////

export function fetch_all_permissions_new(roleId: number): AsyncAction {
	return function(context, dispatch) {
		context.then(data => dispatch(_fetchAllPermissions((data as IJson).data)));
		return axios().get(`PermissionToRole/role/${roleId}'`);
	};
}

export function fetch_all_roles_New(): AsyncAction {
	return function(context, dispatch) {
		context.then(data => dispatch(_fetchAllRoles((data as IJson).data)));
		return axios().get(`/role`);
	};
}

export function create_role_NEW(data: Partial<IRole>): AsyncAction {
	return function(context, dispatch) {
		context.then(() => dispatch(fetch_all_roles_New()));
		return axios().post(`PermissionToRole/full`, data);
	};
}

export function clone_role(data: Partial<IRole>): AsyncAction {
	return function(context, dispatch) {
		context.then(() => dispatch(fetch_all_roles_New()));
		return axios().post(`Role/clone`, data);
	};
}

export function update_role_NEW(data: Partial<IRole>): AsyncAction {
	return function(context, dispatch) {
		context.then(() => dispatch(fetch_all_roles_New()));
		return axios().patch(`Role/${data?.Id}`, data);
	};
}

export function fetch_all_taskerRoleUsers(): AsyncAction {
	return function(context, dispatch) {
		context.then(json => dispatch(_fetchAllUsers((json as IJson).data)));
		return axios().get(`TaskerUser/activeWithRoles`);
	};
}

export function update_permissionToRole(roleId: number, items: IPermissionTmp[]): AsyncAction {
	return function(context, dispatch) {
		context.then(data => {
			dispatch(_updatePermissions((data as IJson).data));
			return dispatch(session_loginWithToken());
		});
		return axios().patch(`PermissionToRole/${roleId}`, items);
	};
}

export function delete_role(roleId: number): AsyncAction {
	return function(context, dispatch) {
		context.then(() => dispatch(fetch_all_roles_New()));
		return axios().delete(`role/${roleId}`);
	};
}

export function fetch_role_by_user(id: number): AsyncAction {
	return function(context, dispatch) {
		context.then(data => dispatch(_fetchRolesByUser((data as IJson).data)));
		return axios().get(`${basePath}/user/${id}`);
	};
}

export function assign_role_to_user(data: ISetRoles): AsyncAction {
	return function(context, dispatch) {
		context.then(data => dispatch(_receiveFetchRolesByProfile((data as IJson).data)));
		return axios().post(`${basePath}/many`, data);
	};
}

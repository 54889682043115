import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
};

export const Translation = {
	pl: {
		Kanban: {
			Filters: {
				Options: {
					Attachment: {
						tab: 'Załączniki',
						onlyWith: 'Tylko z załącznikami',
						onlyWithout: 'Tylko bez załączników',
						off: 'Wyłączone',
					},
				},
			},
		},
	},
	en: {
		Kanban: {
			Filters: {
				Options: {
					Attachment: {
						tab: 'Attachments',
						onlyWith: 'Only with attachments',
						onlyWithout: 'Only with out attachments',
						off: 'Off',
					},
				},
			},
		},
	},
};

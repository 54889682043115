import clsx from 'clsx';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Close } from '@mui/icons-material';
import { IconButton, Typography } from '@mui/material';

import { EParameter, EUrlType, IWorkLog } from '@skillandchill/tasker-types';
import { StartPause, VisibilityControl } from '@skillandchill/tasker-widgets2';

import { FavouriteIssue } from '@/layouts/Widgets/FavouriteIssue';
import { work_log_finish_NEW, work_log_pause_NEW, work_log_start_NEW } from '@/store/actions';
import { IReducerState } from '@/store/reducers/types';
import { Dispatch } from '@/store/types';
import { useTrans } from '@/utils/hooks/useTrans';

import { getIssueTitle, startPauseShouldBeVisible } from 'view/IssueModal/IssueModalTitle/utils';

import { issueModalDataContext } from '..';

import { Props } from './modal';
import { MoreOptions } from './MoreOptions';
import { Resources } from './resources';
import { useStyles } from './styles';

export const IssueModalTitle = (props: Props) => {
	const { handleCloseModal, handleDeleteIssueWithConfirmation } = props;
	const classes = useStyles();
	const dispatch: Dispatch = useDispatch();
	const { isEdittingIssue } = useContext(issueModalDataContext);
	const { t } = useTrans('IssueModal.IssueModalTitle');

	const issue = useSelector((state: IReducerState) => state?.IssueModalEditor?.issueModal);
	const activeWorklogs: IWorkLog[] = useSelector((state: IReducerState) => state?.DashBoard?.activeWorklogs);
	const preference = useSelector((x: IReducerState) => x?.Session?.preferences);

	const [urls, setUrls] = useState<string[]>(getUrls());
	const [canOpenUrl, setCanOpenUrl] = useState<boolean | undefined>(getCanOpenUrl());

	useEffect(() => {
		if (!preference) return;

		setCanOpenUrl(getCanOpenUrl());
	}, [preference]);

	useEffect(() => {
		setUrls(getUrls());
	}, [issue?.IssueUrl]);

	const prefix = useMemo(() => {
		return issue?.IsDeleted ? Resources.deleted : Resources.GlobalResources.empty;
	}, [issue]);

	function getUrls() {
		return issue?.IssueUrl?.filter(x => x?.UrlTypeId === EUrlType.Meet)?.map(y => y?.Url) ?? [];
	}

	function getCanOpenUrl() {
		if (!preference) return;
		const filtered = preference?.filter(
			x => x?.ParameterId == EParameter.User_OpenMeetWithStartClock && !!x?.ValueBoolean
		);

		return filtered?.length > 0;
	}

	return (
		<div className={clsx(classes.flexYCenter, classes.contentSpaceBetween)}>
			<div className={classes.flexYCenter}>
				<VisibilityControl
					condition={isEdittingIssue}
					alternative={<Typography variant={Resources.GlobalResources.h5}>{t('newIssue')}</Typography>}
				>
					<FavouriteIssue issue={issue} />
					<Typography className={classes.title}>
						<span className={classes.prefix}>{prefix}</span>
						<span>{getIssueTitle(issue)}</span>
					</Typography>
				</VisibilityControl>
			</div>
			<div className={classes.flexYCenter}>
				<VisibilityControl condition={isEdittingIssue}>
					<MoreOptions handleDeleteIssueWithConfirmation={handleDeleteIssueWithConfirmation} />
				</VisibilityControl>
				<VisibilityControl condition={startPauseShouldBeVisible(issue, isEdittingIssue)}>
					<StartPause
						active={activeWorklogs?.some(worklog => worklog?.Issue?.Id === issue?.Id)}
						handleStart={() => dispatch(work_log_start_NEW(issue?.Id))}
						handlePause={() => dispatch(work_log_pause_NEW(issue?.Id))}
						handleFinish={() => dispatch(work_log_finish_NEW(issue?.Id))}
						urls={urls}
						canOpen={canOpenUrl}
					/>
				</VisibilityControl>
				<IconButton onClick={handleCloseModal}>
					<Close className={classes.closeIcon} />
				</IconButton>
			</div>
		</div>
	);
};

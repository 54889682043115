import { addDays, format } from 'date-fns';

import { EOption, FullFilters } from '@skillandchill/tasker-types';
import { EFormats } from '@skillandchill/tasker-widgets2/dist/utils';
import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

import { IFilters } from 'view/SearchBar/model';
import { DEFAULT_SEARCH_BY } from 'view/SearchBar/Search/SearchBy/resources';
import { IFilterObject } from 'view/SearchBar/Tags/model';

export const Resources = {
	...GlobalResources,
	ThirtyDayOlderCheckbox: 'ThirtyDayOlderCheckbox',
	add: 'add',
	extended: 'extended' as const,
	issuesListPath: 'issues',
	projectsListPath: 'projects',
	topBar: 'topBar',
	menu: (id: number) => `Profile_menu_type_${id}`,
};

export const Translation = {
	pl: {
		SearchBar: {
			filterButton: { button: 'Filter', tooltip: 'Filtruj' },
			displayModule: { tiles: 'Kafelki', list: 'Lista' },
			showCreated: 'Pokaż stworzone w ciągu miesiąca',
		},
	},
	en: {
		SearchBar: {
			filterButton: { button: 'Filter', tooltip: 'Filter' },
			displayModule: { tiles: 'Tiles', list: 'List' },
			showCreated: 'Show created in this month',
		},
	},
};

export const getInitialFilter = (): IFilters => {
	return JSON.parse(JSON.stringify(initialsParamsValue));
};

export const getInitialFilterNew = (): FullFilters => {
	return JSON.parse(JSON.stringify(initialsParamsValueV2));
};

export const getInitialFilterWorkTimeNew = (): FullFilters => {
	return JSON.parse(
		JSON.stringify({
			...initialsParamsValueV2,
			WorkLogs: {
				Start: format(addDays(new Date(), -7), EFormats.EDateFNS.DefaultDateFormat),
				End: format(new Date(), EFormats.EDateFNS.DefaultDateFormat),
			},
		})
	);
};

const defaultId = {
	id: -1,
	object: (undefined as unknown) as IFilterObject,
};

const defaultDate = {
	begin: undefined,
	end: undefined,
};

const initialsParamsValue: IFilters = {
	issue: {
		title: '',
		assignedTo: Object.assign({}, defaultId),
		reportedBy: Object.assign({}, defaultId),
		creator: Object.assign({}, defaultId),
		auditor: Object.assign({}, defaultId),
		observer: Object.assign({}, defaultId),
		startDate: defaultDate,
		dueDate: defaultDate,
		createdDateTime: null,
		isCustomerVisible: undefined,
		reportedByPortal: Object.assign({}, defaultId),
		Comments: EOption.Off,
	},
	customer: {
		customerId: Object.assign({}, defaultId),
	},
	projectId: Object.assign({}, defaultId),
	project: {
		startDate: defaultDate,
		dueDate: defaultDate,
		name: '',
	},
	portal: {
		title: '',
		assignedTo: Object.assign({}, defaultId),
	},
	dictionaries: {
		name: '',
	},
	dictionary: {
		value: '',
	},
	counter: 0,
	tags: [],
	workLogs: {
		startDate: format(addDays(new Date(), -7), EFormats.EDateFNS.DefaultDateFormat),
		endDate: format(new Date(), EFormats.EDateFNS.DefaultDateFormat),
	},
};

const initialsParamsValueV2: FullFilters = {
	Comment: { Comment: '' },
	Project: { DateRange: { UpdatedDateTime: {}, CreateDateTime: {} }, Ids: [] },
	Issue: {
		DateRange: { CreateDateTime: {}, UpdatedDateTime: {} },
		TagIds: [],
		PortalStatusIds: [],
		PortalTagIds: [],
		TextBy: DEFAULT_SEARCH_BY,
		IssueFormIds: [],
		ShowDeleted: false,
		ShowOnlyWithAttachment: EOption.Off,
		Comments: EOption.Off,
	},
	Customer: {
		Ids: [],
	},
	Form: { Text: '' },
	WorkLogs: {},
	TileContext: {},
	EmployeeAgreement: {},
};

export const ColorsCSS = {
	Assigned: '#22c55e40',
	Reporter: 'rgb(83,0,0)',
	Auditor: 'rgb(0,110, 0)',
	Creator: 'rgb(152,128,0)',
	Observer: 'rgb(100, 100, 200)',
};

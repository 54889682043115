import {
	EParameter,
	IIssue,
	IKanbanWithClock,
	IPage,
	ITaskerUserSetting,
	IUserNotification,
	IWorkLog,
} from '@skillandchill/tasker-types';
import { ICheckListValue } from '@skillandchill/tasker-types/dist/types/dataBaseTypes/checkListValue';
import { IForm } from '@skillandchill/tasker-types/dist/types/dataBaseTypes/form';

import * as actionTypes from '@/store/actions';
import { ObjectAction } from '@/store/types';

import { updateIssueWorklogStatus } from '../utils/dashBoardReducerUtils';

const State = {
	workableIssue: [] as IIssue[],
	SearchWorkableIssues: { Data: [], Count: 0 } as IPage<IIssue[]>,
	workableIssuesCount: undefined as number | undefined,
	activeWorklogs: [] as IWorkLog[],
	countOfNotReadUserNotifications: 0 as number,
	closeWorklogStarter: 0,
	kanbanChangedIssueClock: 0,
	userNotificationsPopover: [] as IUserNotification[],
	EndOfWork: {
		form: {} as IForm,
		forms: [] as IForm[],
		values: {} as { [x: number]: ICheckListValue[] }, //FieldDefinitionId jest tuttaj X
	},
};
export type IDashBoardState = typeof State;

const dashBoardReducer = (state = State, action: ObjectAction): IDashBoardState => {
	switch (action.type) {
		case actionTypes.ISSUE_FETCH_WORKABLE_NEW: {
			return {
				...state,
				workableIssue:
					action?.from == 0 ? action?.workableIssues : [...state.workableIssue, ...action.workableIssues],
				workableIssuesCount: action?.workableIssuesCount,
			};
		}
		case actionTypes.SEARCH_FETCH_WORKABLE_ISSUES: {
			return {
				...state,
				SearchWorkableIssues: action.SearchWorkableIssues,
			};
		}
		case actionTypes.CLEAR_WORKABLE_ISSUES: {
			return {
				...state,
				workableIssue: [],
				workableIssuesCount: undefined,
			};
		}
		case actionTypes.USE_CLOCK:
		case actionTypes.WORKLOG_FETCH_ACTIVE_NEW: {
			const activeWorklogs = action?.worklogs?.active?.filter((x: IWorkLog) => x?.IsActive == true) ?? [];
			const activeArray = action?.worklogs?.active;
			const lastActiveIssueId =
				activeArray && activeArray.length > 0 ? activeArray[activeArray.length - 1]?.IssueId : null;

			const updatedSearchWorkableIssues =
				updateIssueWorklogStatus(state?.SearchWorkableIssues?.Data, lastActiveIssueId, true) ?? [];

			return {
				...state,
				activeWorklogs,
				SearchWorkableIssues: {
					...state?.SearchWorkableIssues,
					Data: updatedSearchWorkableIssues,
				},
			};
		}

		case actionTypes.USE_CLOCK_PAUSE_FINISH: {
			const preferences: ITaskerUserSetting[] = action?.worklogs?.preferences;
			const stoppedArray = action?.worklogs?.stopped;
			const lastStoppedIssueId =
				stoppedArray && stoppedArray.length > 0 ? stoppedArray[stoppedArray.length - 1]?.IssueId : null;
			const lastStoppedId =
				stoppedArray && stoppedArray.length > 0 ? stoppedArray[stoppedArray.length - 1]?.Id : null;

			const updatedSearchWorkableIssues =
				updateIssueWorklogStatus(state?.SearchWorkableIssues?.Data, lastStoppedIssueId, false) ?? [];

			let activeWorklogs: IWorkLog[] = action?.worklogs?.active ?? [];

			if (preferences && lastStoppedId) {
				const clockPreference = preferences?.find(x => x?.ParameterId === EParameter.User_BlockMultiClock);
				if (clockPreference?.ValueBoolean === false)
					activeWorklogs = state?.activeWorklogs?.filter(x => x?.Id != lastStoppedId);
			}

			return {
				...state,
				activeWorklogs: activeWorklogs,
				SearchWorkableIssues: {
					...state?.SearchWorkableIssues,
					Data: updatedSearchWorkableIssues,
				},
			};
		}

		case actionTypes.KEY_WORKLOG_MANAGE: {
			return {
				...state,
				activeWorklogs: action?.worklogs?.StartWorkLog ?? [],
			};
		}
		case actionTypes.SET_USER_NOTIFICATIONS_POPOVER_DATA: {
			return {
				...state,
				countOfNotReadUserNotifications: action?.countOfNotReadUserNotifications,
				userNotificationsPopover: action?.notifications,
			};
		}
		case actionTypes.SET_USER_NOTIFICATIONS_LIST_DATA:
		case actionTypes.SET_COUNT_OF_NOT_READ_USER_NOTIFICATIONS: {
			return {
				...state,
				countOfNotReadUserNotifications: action?.countOfNotReadUserNotifications,
			};
		}

		case actionTypes.TOP_BAR_SET: {
			return {
				...state,
				closeWorklogStarter: state.closeWorklogStarter + 1,
			};
		}
		case actionTypes.SET_FAVOURITE_ISSUES_ORDER: {
			return {
				...state,
				workableIssue: action?.issues,
			};
		}
		case actionTypes.KANBAN_ISSUE_DID_CHANGE: {
			const data: IKanbanWithClock = action.data;
			return {
				...state,
				kanbanChangedIssueClock: state.kanbanChangedIssueClock + (data?.DidChangeClock ? 1 : 0),
			};
		}
		case actionTypes.FORM_FETCH: {
			return {
				...state,
				EndOfWork: {
					...state?.EndOfWork,
					form: action.data,
					forms: action.data.forms,
					values: action?.data?.Values ?? {},
				},
			};
		}
		case actionTypes.CHECKLIST_UPDATE_LOCAL_VALUES: {
			return {
				...state,
				EndOfWork: {
					...state?.EndOfWork,
					values: action?.data ?? {},
				},
			};
		}
		case actionTypes.RESET_END_OF_WORK: {
			return {
				...state,
				EndOfWork: State.EndOfWork,
			};
		}

		default: {
			return state;
		}
	}
};

export default dashBoardReducer;

import Axios, { CancelTokenSource } from 'axios';

import { AsyncAction } from '@skillchill/redux-promisify';

import {
	FullFilters,
	IKanban,
	IKanbanData,
	IKanbanIssueList,
	IKanbanWithClock,
	IProject,
	ITaskerUser,
} from '@skillandchill/tasker-types';
import {
	IIssueToSwimlaneToOrder,
	IKanbanSwimlane,
	IMapTagToFieldDefinition,
	ISwimlaneConfiguration,
} from '@skillandchill/tasker-types/dist/types';
import { IKanbanCreate, IKanbanUpdate } from '@skillandchill/tasker-types/dist/types/endPoints/kanban';

import { IJson } from '@/store/ITypes';
import { KanbanTileDropData } from '@/store/reducers/New/kanbanReducer';
import { Action, ActionType } from '@/store/types';
import axios from '@/utils/axios';

import { IOldColumnsCounts } from '../../../temporaryInterfaces';

export const FETCH_ALL_KANBANS: ActionType = 'FETCH_ALL_KANBANS';
export const FETCH_ONE_KANBAN: ActionType = 'FETCH_ONE_KANBAN';
export const FETCH_KANBAN_ISSUES: ActionType = 'FETCH_KANBAN_ISSUES';
export const SET_KANBAN_ISSUES: ActionType = 'SET_KANBAN_ISSUES';
export const SET_KANBAN_TAGS: ActionType = 'SET_KANBAN_TAGS';
export const DELETE_KANBAN: ActionType = 'DELETE_KANBAN';
export const DELETE_ISSUE_FROM_KANBAN: ActionType = 'DELETE_ISSUE_FROM_KANBAN';
export const SET_KANBAN_COLLAPSE: ActionType = 'SET_KANBAN_COLLAPSE';
export const RESET_CLOCK_LISTENER: ActionType = 'RESET_CLOCK_LISTENER';
export const KANBAN_ISSUE_DID_CHANGE: ActionType = 'KANBAN_ISSUE_DID_CHANGE';
export const KANBAN_USERS_GET: ActionType = 'KANBAN_USERS_GET';
export const FETCH_PROJECTS_WITH_FORMS: ActionType = 'FETCH_PROJECTS_WITH_FORMS';
export const FETCH_FORM_MAPPINGS: ActionType = 'FETCH_FORM_MAPPINGS';
export const SET_KANBAN_EDIT_MAPPINGS: ActionType = 'SET_KANBAN_EDIT_MAPPINGS';
export const FETCH_SWIMLANES_FOR_KANBAN: ActionType = 'FETCH_SWIMLANES_FOR_KANBAN';
export const DELETE_SWIMLANE: ActionType = 'DELETE_SWIMLANE';
export const CLEAR_ISSUES: ActionType = 'CLEAR_ISSUES';
export const SET_KANBAN_SWIMLANE_ISSUE_COUNTS: ActionType = 'SET_KANBAN_SWIMLANE_ISSUE_COUNTS';
export const SET_ISSUE_IDS_WAITING_FOR: ActionType = 'SET_ISSUE_IDS_WAITING_FOR';
export const SET_DROP_RESULT: ActionType = 'SET_DROP_RESULT';
export const FETCH_SWIMLANES_WITH_CONFIGURATIONS: ActionType = 'FETCH_SWIMLANES_WITH_CONFIGURATIONS';
export const SET_NEW_USER_CONFIGURATION: ActionType = 'SET_NEW_USER_CONFIGURATION';
export const KANBAN_LITE_MODE_CHANGE: ActionType = 'KANBAN_LITE_MODE_CHANGE';
export const SET_SWIMLANE_COLLAPSE: ActionType = 'SET_SWIMLANE_COLLAPSE';

const baseKanbanPath = 'Kanban';
const baseSwimlaneConfigurationPath = 'swimlaneConfiguration';
const baseMapPath = 'mapTagToFieldDefinition';
const baseSwimlanePath = 'Swimlane';

export function resetClockListener(): Action {
	return { type: RESET_CLOCK_LISTENER };
}
function _fetchAllKanbans(kanbans: IKanban[]): Action {
	return {
		type: FETCH_ALL_KANBANS,
		kanbans,
	};
}

function _fetchOneKanban(kanban: IKanban): Action {
	return {
		type: FETCH_ONE_KANBAN,
		kanban,
	};
}
function _deleteKanban(kanbans: IKanban[]): Action {
	return {
		type: DELETE_KANBAN,
		kanbans,
	};
}

function _fetchKanbanIssues(data: IKanbanData, from: number, swimlaneId: number): Action {
	return {
		type: FETCH_KANBAN_ISSUES,
		data,
		from,
		swimlaneId,
	};
}

export function _setKanbanIssues(data: { [x: number]: IKanbanIssueList[] }): Action {
	return {
		type: SET_KANBAN_ISSUES,
		data,
	};
}

export function deleteIssueFromKanban(issueId: number, swimlaneId: number, columnTagId: number): Action {
	return {
		type: DELETE_ISSUE_FROM_KANBAN,
		issueId,
		swimlaneId,
		columnTagId,
	};
}

export function _kanbanIssueDidChange(data: IKanbanWithClock): Action {
	return {
		type: KANBAN_ISSUE_DID_CHANGE,
		data,
	};
}

export function _setKanbanSwimlaneIssueCounts(data: { [x: number]: { [x: number]: number } }): Action {
	return {
		type: SET_KANBAN_SWIMLANE_ISSUE_COUNTS,
		data,
	};
}
export function _setIssueIdsWaitingFor(data: number, isAdd: boolean): Action {
	return {
		type: SET_ISSUE_IDS_WAITING_FOR,
		data,
		isAdd,
	};
}
export function _setDropResult(result: KanbanTileDropData | undefined): Action {
	return {
		type: SET_DROP_RESULT,
		result,
	};
}

export function _getProjectsWithForms(projectsWithForms: IProject[]): Action {
	return {
		type: FETCH_PROJECTS_WITH_FORMS,
		projectsWithForms,
	};
}

export function _getFormMappings(mappings: Partial<IMapTagToFieldDefinition>[]): Action {
	return {
		type: FETCH_FORM_MAPPINGS,
		mappings,
	};
}

export function _deleteSwimlane(swimlaneId: number): Action {
	return {
		type: DELETE_SWIMLANE,
		swimlaneId,
	};
}

export function _clearIssues(): Action {
	return {
		type: CLEAR_ISSUES,
	};
}

export function fetchAllKanbans(): AsyncAction {
	return function(context, dispatch) {
		context.then(json => dispatch(_fetchAllKanbans((json as IJson)?.data)));
		return axios().get(baseKanbanPath);
	};
}

export function _fetchSwimlanesWithConfigurations(swimlanes: IKanbanSwimlane[]): Action {
	return {
		type: FETCH_SWIMLANES_WITH_CONFIGURATIONS,
		swimlanes,
	};
}

export function _setSwimlaneCollapse(kanbanId: number, swimlaneId: number, value: boolean): Action {
	return {
		type: SET_SWIMLANE_COLLAPSE,
		kanbanId,
		swimlaneId,
		value,
	};
}
export function _setNewUserConfiguration(user: Partial<ITaskerUser>): Action {
	return {
		type: SET_NEW_USER_CONFIGURATION,
		user,
	};
}

function _changeKanbanLiteMode(configuration: Partial<ISwimlaneConfiguration>): Action {
	return {
		type: KANBAN_LITE_MODE_CHANGE,
		configuration,
	};
}

const kanbanRequestTokens = {} as { [x: number]: { from: number; cancelToken: CancelTokenSource } };

export function fetchKanbanIssues(
	kanbanId: number,
	swimlaneId: number,
	from: number,
	count: number,
	oldColumnCounts: IOldColumnsCounts[] | undefined,
	filters: FullFilters
): AsyncAction {
	if (kanbanRequestTokens[swimlaneId]?.from == from && kanbanRequestTokens[swimlaneId]?.cancelToken) {
		kanbanRequestTokens[swimlaneId]?.cancelToken?.cancel();
	}
	return function(context, dispatch) {
		const cancelToken = Axios.CancelToken.source();
		kanbanRequestTokens[swimlaneId] = { from, cancelToken };

		context.then(json => {
			dispatch(_fetchKanbanIssues((json as IJson)?.data, from, swimlaneId));
		});
		return axios().post(`${baseKanbanPath}/getKanbanIssues/${kanbanId}/${swimlaneId}/${from}/${count}`, {
			oldColumnCounts,
			filters,
		});
	};
}

export function fetchOneKanban(id: number): AsyncAction {
	if (id === undefined) throw new Error('Kanban has no Id');
	return function(context, dispatch) {
		context.then(json => dispatch(_fetchOneKanban((json as IJson).data)));
		return axios().get(`${baseKanbanPath}/${id}`);
	};
}

export function createKanban(kanban: IKanbanCreate, swimlanes: Partial<IKanbanSwimlane>[]): AsyncAction {
	return function(context, dispatch) {
		context.then(json => dispatch(_fetchOneKanban((json as IJson).data)));
		return axios().post(baseKanbanPath, { kanban, swimlanes });
	};
}

export function updateKanbanMappings(
	mappings: Partial<IMapTagToFieldDefinition>[],
	kanbanId: number,
	formId: number
): AsyncAction {
	return function(context, dispatch) {
		context.then(json => {
			dispatch(_getFormMappings((json as IJson)?.data?.mappings));
		});
		return axios().patch(`${baseKanbanPath}/mappings/${kanbanId}/${formId}`, { mappings });
	};
}

export function updateKanbanGeneral(kanban: IKanbanUpdate, id: number): AsyncAction {
	return function(context, dispatch) {
		context.then(json => {
			dispatch(_fetchOneKanban((json as IJson)?.data?.kanban));
		});
		return axios().patch(`${baseKanbanPath}/general/${id}`, { kanban });
	};
}

export function dragIssue(
	issueId: number,
	columnTagId: number,
	issueToSwimlaneToOrder: Partial<IIssueToSwimlaneToOrder>[],
	isSameColumn?: boolean,
	mappingIds?: number[]
): AsyncAction {
	return function(context, dispatch) {
		context.then(json => dispatch(_kanbanIssueDidChange((json as IJson).data)));
		return axios().post(`${baseKanbanPath}/changeIssueFieldValue/${columnTagId}/${issueId}/${isSameColumn}`, {
			issueToSwimlaneToOrder,
			mappingIds,
		});
	};
}

export function deleteKanban(id: number): AsyncAction {
	return function(context, dispatch) {
		context.then(json => dispatch(_deleteKanban((json as IJson).data)));
		return axios().delete(`${baseKanbanPath}/${id}`);
	};
}

export function getProjectsWithForms(projectIds: number[]): AsyncAction {
	return function(context, dispatch) {
		context.then(json => dispatch(_getProjectsWithForms((json as IJson).data)));
		return axios().post(`Project/includeForms`, projectIds);
	};
}

export function getMappingsByFormInKanban(formId: number, kanbanId: number): AsyncAction {
	return function(context, dispatch) {
		context.then(json => dispatch(_getFormMappings((json as IJson).data)));
		return axios().get(`mapTagToFieldDefinition/byFormInKanban/${formId}/${kanbanId}`);
	};
}

export function fetchKanbanCounters(kanbanId: number, filters: FullFilters): AsyncAction {
	return function(context, dispatch) {
		context.then(json => dispatch(_setKanbanSwimlaneIssueCounts((json as IJson).data)));
		return axios().post(`${baseKanbanPath}/counters/${kanbanId}`, filters);
	};
}

export function update_Swimlanes(data: Partial<IKanbanSwimlane>[]): AsyncAction {
	return function(context, dispatch) {
		context.then(json => dispatch(_fetchSwimlanesWithConfigurations((json as IJson).data)));
		return axios().patch(baseSwimlanePath, data);
	};
}

export function delete_Swimlane(swimlaneId: number, kanbanId: number): AsyncAction {
	return function(context, dispatch) {
		context.then(() => dispatch(_deleteSwimlane(swimlaneId)));
		return axios().delete(`${baseSwimlanePath}/${swimlaneId}/${kanbanId}`);
	};
}

export function generateMappingsByName(
	kanbanId: number,
	forAll: boolean,
	onlyMissing: boolean,
	formId?: number
): AsyncAction {
	return function(context, dispatch) {
		context.then(json => dispatch(_getFormMappings((json as IJson).data)));
		return axios().post(`${baseMapPath}/generateMappingsByName`, { kanbanId, forAll, onlyMissing, formId });
	};
}

export function createOrUpdateSwimlaneConfiguration(
	swimlaneConfiguration: Partial<ISwimlaneConfiguration>,
	kanbanId: number
): AsyncAction {
	return function(context, dispatch) {
		context.then(json => {
			dispatch(_fetchSwimlanesWithConfigurations((json as IJson).data));
		});
		return axios().post(`${baseSwimlaneConfigurationPath}/${kanbanId}`, { ...swimlaneConfiguration });
	};
}

export function controlSwimlaneCollapse(kanbanId: number, swimlaneId: number, value: boolean): AsyncAction {
	return function(context, dispatch) {
		context.then(() => {
			dispatch(_setSwimlaneCollapse(kanbanId, swimlaneId, value));
		});
		return axios().post(`${baseSwimlaneConfigurationPath}/${kanbanId}/${swimlaneId}`, { value });
	};
}

export function changeKanbanLiteMode(value: boolean): AsyncAction {
	return function(context, dispatch) {
		context.then(json => {
			dispatch(_changeKanbanLiteMode((json as IJson).data));
		});
		return axios().patch(`${baseSwimlaneConfigurationPath}/${value}`);
	};
}

export function fetchSwimlanesForConfiguration(kanbanId: number): AsyncAction {
	return function(context, dispatch) {
		context.then(json => {
			dispatch(_fetchSwimlanesWithConfigurations((json as IJson).data));
		});
		return axios().get(`${baseSwimlanePath}/configuration/${kanbanId}`);
	};
}

import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
};

export const Translation = {
	pl: {
		OrganizationPanel: {
			PasswordPolicy: {
				CardActions: {
					title: 'Polityka haseł',
					description:
						'Zdefiniuj reguły dotyczące haseł użytkowników, obowiązujące w Twojej organizacji.\nKliknięcie w ikonę reguły pozwala na jej aktywację/dezaktywację.',
					cancel: 'Anuluj zmiany',
					save: 'Zapisz zmiany',
					edit: 'Edytuj politykę haseł',
					sureChange: 'Czy jesteś pewien, że chcesz wymusić zmianę haseł w całej organizacji',
					passChange: 'Wnioskuj o zmianę haseł w organizacji',
				},
			},
		},
	},
	en: {
		OrganizationPanel: {
			PasswordPolicy: {
				CardActions: {
					title: 'Password policy',
					description:
						'Define rules for user passwords that apply in your organization.\nClicking on the rule icon allows you to activate/deactivate it.',
					cancel: 'Cancel changes',
					save: 'Save changes',
					edit: 'Edit password policy',
					sureChange: 'Are you sure that you want to force change passwords in organization.',
					passChange: 'Request change passwords in organization.',
				},
			},
		},
	},
};

import clsx from 'clsx';
import { useContext, useEffect, useState } from 'react';

import { EFieldDefinitionType, EInputTypes } from '@skillandchill/tasker-types';
import { EKeys } from '@skillandchill/tasker-types/dist/types/common/keys';
import { CustomInput } from '@skillandchill/tasker-widgets2';

import { IssueFormSectionsWidgetContext } from '../..';
import { IssuePropertyTitle } from '../IssuePropertyTitle';
import { useInitialAttributeDefinitionValues } from '../utils/useInitialAttributeDefinitionValues';

import { Props } from './model';
import { useStyles } from './styles';

export const TextAreaIssueProperty = (props: Props) => {
	const { issueFormSectionToIssueField, inEdit } = props;
	const classes = useStyles();

	const [rows, setRows] = useState(3);

	const { handleChange, state } = useContext(IssueFormSectionsWidgetContext);

	useEffect(() => {
		let rowsTmp = rows;
		function handleClick(e: KeyboardEvent) {
			if (e.shiftKey && e.altKey && e.key === EKeys.Minus) rowsTmp = rowsTmp - 1;
			if (e.shiftKey && e.altKey && e.key === EKeys.Plus) rowsTmp = rowsTmp + 1;

			setRows(rowsTmp);
		}

		window.addEventListener('keydown', handleClick);

		return () => {
			window.removeEventListener('keydown', handleClick);
		};
	}, []);

	const AttributeDefinitionId = issueFormSectionToIssueField?.AttributeDefinitionId as number;

	const initial = useInitialAttributeDefinitionValues(issueFormSectionToIssueField);

	const initialInputValue = initial?.length ? (initial?.[0]?.ValueText as string) : '';

	const newValue = state && (state?.[EFieldDefinitionType.TextArea]?.[AttributeDefinitionId] as string | undefined);

	const value = newValue ?? initialInputValue ?? '';

	function getNonEditValue() {
		if (initialInputValue) return initialInputValue;
		else return '';
	}

	return (
		<div className={clsx(classes.flexYCenter, classes.container)}>
			<IssuePropertyTitle {...props} />
			<div className={clsx(classes.flexGrow, classes.editorContainer)}>
				<CustomInput
					type={EInputTypes.text}
					label=""
					handleChange={val => handleChange && handleChange(val as string, issueFormSectionToIssueField)}
					value={value}
					inEdit={inEdit}
					canSetInputInNonEdit
					multiline={true}
					rows={rows}
					hideErrorMessage
					nonEditComponent={
						<CustomInput
							type={EInputTypes.text}
							label=""
							value={getNonEditValue()}
							multiline={true}
							rows={rows}
							disabled
						/>
					}
				/>
			</div>
		</div>
	);
};

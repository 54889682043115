import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { getGlobalStyles } from '@skillandchill/tasker-widgets2/dist/utils/assets/GlobalStyles/styles';

export const useStyles = makeStyles(
	(theme: Theme) => ({
		...getGlobalStyles(),
		prefix: { color: 'red' },
		starIcon: { fontSize: '30px' },
		closeIcon: { fontSize: '30px' },
		title: {
			maxWidth: '1000px',
			marginRight: theme.spacing(1),
			display: 'flex',
			flexDirection: 'row',
			gap: theme.spacing(0.5),
			textOverflow: 'ellipsis',
			overflow: 'hidden',
			whiteSpace: 'nowrap',
			'& span': {
				textOverflow: 'ellipsis',
				overflow: 'hidden',
				whiteSpace: 'nowrap',
			},
		},
	}),
	{ name: `IssueModal_IssueModalTitle` }
);

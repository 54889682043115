import { t } from 'i18next';

import { EIssueModalDetailsPageTabs, ITab } from './model';

export const getTabs = (): ITab[] => {
	return [
		{
			label: t('IssueModal.IssueModalContent.IssueDetailsPage.Tabs.description'),
			value: EIssueModalDetailsPageTabs.Description,
			disableNew: false,
		},
		{
			label: t('IssueModal.IssueModalContent.IssueDetailsPage.Tabs.comments'),
			value: EIssueModalDetailsPageTabs.Comments,
			disableNew: true,
		},
		{
			label: t('IssueModal.IssueModalContent.IssueDetailsPage.Tabs.attachments'),
			value: EIssueModalDetailsPageTabs.Attachments,
			disableNew: false,
		},
	];
};

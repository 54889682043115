import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	...GlobalResources,
};

export const Translation = {
	pl: {
		Kanban: {
			Filters: {
				Options: {
					IsDeleted: {
						tab: 'Usunięte zgłoszenia',
						showDeleted: 'Pokaż usunięte zgłoszenia',
					},
				},
			},
		},
	},
	en: {
		Kanban: {
			Filters: {
				Options: {
					Comments: {
						tab: 'Deleted issues',
						showDeleted: 'Show deleted issues',
					},
				},
			},
		},
	},
};

import clsx from 'clsx';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import AccessTimeTwoToneIcon from '@mui/icons-material/AccessTimeTwoTone';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import MenuIcon from '@mui/icons-material/Menu';
import NotificationsIcon from '@mui/icons-material/NotificationsOutlined';
import ScheduleSendIcon from '@mui/icons-material/ScheduleSend';
import { AppBar, Avatar, Badge, Button, Hidden, IconButton, Link, Toolbar, Tooltip, Typography } from '@mui/material';

import {
	EBoolean,
	ENotificationReadStatusFilter,
	IIssue,
	ITaskerUser,
	IWorkLog,
	Permission,
} from '@skillandchill/tasker-types';
import { NotSpecified, True } from '@skillandchill/tasker-types/dist/types/custom/advencedBasicTypes';
import { IVersion } from '@skillandchill/tasker-types/dist/types/dataBaseTypes/version';
import { useControlContext, useOpenContext } from '@skillandchill/tasker-widgets2/dist/utils/controlContext';
import Popup from '@skillandchill/tasker-widgets2/dist/widgets/Popup';
import { VisibilityControl } from '@skillandchill/tasker-widgets2/dist/widgets/VisibilityControl';

import { LogOutButton } from '@/layouts/Dashboard/NavBar/DrawerContent/LogOutButton';
import { EndOfDay } from '@/layouts/Dashboard/TopBar/EndOfDay';
import HotkeysTooltip from '@/layouts/Dashboard/TopBar/HotkeysTooltip';
import LanguageButton from '@/layouts/Dashboard/TopBar/LanguageButton';
import { getIssue, getStyleProps } from '@/layouts/Dashboard/TopBar/utils';
import { WorklogStarter } from '@/layouts/Dashboard/WorklogStarter';
import {
	fetch_Newest_Version,
	get_user_notifications_popover,
	issue_fetchWorkable,
	navBar_SetState,
	reset_session_metadata,
	work_log_finish_NEW,
	work_log_pause_NEW,
	userHasEndedDay as user_HasEndedDay,
	userMustChangePassword,
} from '@/store/actions';
import { IReducerState } from '@/store/reducers/types';
import { Dispatch } from '@/store/types';
import { useSetInitialSettingsAfterLogin } from '@/utils/hooks/useSetInitialSettingsAfterLogin/useSetInitialSettingsAfterLogin';
import { useTrans } from '@/utils/hooks/useTrans';
import { CheckRoles } from '@/utils/permissions';
import { TaskerLogoLoader } from '@/views/Agreement/Agreements/AgreementEdit/TaskerLogoLoader';

import HotkeysPopover from '../HotkeysPopover';
import { NotificationsPopover } from '../NotificationsPopover';

import { LinkToStartPage } from './LinkToStartPage/LinkToStartPage';
import { Props } from './model';
import { Resources } from './resources';
import { useStyles } from './styles';

export const TopBar = (props: Props): JSX.Element => {
	const { onOpenNavBarMobile, className, ...rest } = props;
	const { tString } = useTrans('Dashboard.TopBar');
	const [isClockOpen, setIsClockOpen] = useState<boolean>(false);
	const classes = useStyles(getStyleProps(isClockOpen));

	const activeIssueControlContext = useControlContext();
	const history = useHistory();
	const dispatch: Dispatch = useDispatch();
	const notificationsRef = useRef(null);
	const hotkeysRef = useRef(null);
	const finalFormContext = useOpenContext();

	useSetInitialSettingsAfterLogin();

	const taskerUser: Partial<ITaskerUser> = useSelector((state: IReducerState) => state?.Session?.taskerUser);
	const currentUserPermissions: number[] = useSelector((state: IReducerState) => state.Session?.permissions);
	const userHasEndedDay: EBoolean = useSelector((state: IReducerState) => state.Session?.userHasEndedDay);
	const navBarState: boolean = useSelector((state: IReducerState) => state.Common.sideBarOpen);
	const issuesSelector_NEW: IIssue[] = useSelector((state: IReducerState) => state.DashBoard.workableIssue);
	const activeWorklogs: IWorkLog[] = useSelector((state: IReducerState) => state?.DashBoard?.activeWorklogs);
	const isLoading: boolean = useSelector((state: IReducerState) => state?.Session?.isLoading);
	const version: IVersion = useSelector((state: IReducerState) => state?.Session?.version);
	const language: string = useSelector((state: IReducerState) => state?.Session.language);
	const countOfNotReadUserNotifications: number = useSelector(
		(state: IReducerState) => state.DashBoard.countOfNotReadUserNotifications
	);

	const [openNotifications, setOpenNotifications] = useState<boolean>(false);
	const [openHotkeys, setOpenHotkeys] = useState<boolean>(false);
	const [isVisibleWorkLogBar] = useState<boolean>(
		CheckRoles(currentUserPermissions, Permission.DisplayMenuWorkLogBar)
	);

	useEffect(() => {
		if (language) dispatch(fetch_Newest_Version());
		dispatch(user_HasEndedDay());
		dispatch(userMustChangePassword());
	}, []);

	const handleFetch = (from: number, count: number, tab: string) => {
		dispatch(issue_fetchWorkable(from, count, tab));
	};

	const handleLogout = () => {
		dispatch(reset_session_metadata());
	};

	const handlePauseWorkLog = (id: number, comment?: string): Promise<void> => {
		return dispatch(work_log_pause_NEW(id, comment));
	};

	const handleFinishWorkLog = (id: number, comment?: string): Promise<void> => {
		return dispatch(work_log_finish_NEW(id, comment));
	};

	const handleOpenSmartphoneWorklog = () => {
		setIsClockOpen(!isClockOpen);
	};

	const handleOpenNotificationsPopover = () => {
		setOpenNotifications(true);
		dispatch(get_user_notifications_popover({ filterByReadStatus: ENotificationReadStatusFilter.NotRead }));
	};

	const openScheduleForm = () => {
		finalFormContext.open();
	};

	const onClose = () => {
		finalFormContext.close();
	};

	const onSaveForm = () => {
		onClose();
	};

	return (
		<AppBar {...rest} className={clsx(classes.root)} color={Resources.GlobalResources.inherit}>
			<Toolbar className={clsx(className, classes.fullWidth, classes.logoWrapper)}>
				<LinkToStartPage />
				<Hidden lgUp>
					<IconButton
						className={classes.menuButton}
						size={Resources.GlobalResources.large}
						onClick={e => onOpenNavBarMobile(!!e)}
					>
						<MenuIcon />
					</IconButton>
				</Hidden>
				<Hidden lgDown>
					<IconButton
						size={Resources.GlobalResources.large}
						className={classes.menuButton}
						color={Resources.GlobalResources.primary}
						onClick={() => dispatch(navBar_SetState(!navBarState))}
					>
						<MenuIcon />
					</IconButton>
				</Hidden>
				<VisibilityControl condition={!!version?.ApplicationVersionId}>
					<Link
						className={clsx(classes.flexColumn, classes.pointerCursor, classes.version)}
						href={Resources.versionHref}
					>
						<Typography variant={Resources.GlobalResources.h6}>{version?.ApplicationVersionId}</Typography>
						<Typography variant={Resources.GlobalResources.body2}>{tString('version')}</Typography>
					</Link>
				</VisibilityControl>
				<div className={classes.flexGrow} />
				<Hidden smUp>
					<IconButton
						className={classes.menuButton}
						size={Resources.GlobalResources.large}
						onClick={handleOpenSmartphoneWorklog}
					>
						<AccessTimeTwoToneIcon />
					</IconButton>
				</Hidden>
				<VisibilityControl condition={userHasEndedDay != NotSpecified}>
					<IconButton onClick={openScheduleForm}>
						<ScheduleSendIcon
							color={userHasEndedDay === True ? Resources.disabled : Resources.GlobalResources.secondary}
						/>
					</IconButton>
				</VisibilityControl>
				<VisibilityControl condition={isVisibleWorkLogBar}>
					<Hidden smDown mdUp>
						<Toolbar className={classes.fullWidth}>
							<WorklogStarter
								active
								fullWidth
								isLoading={isLoading}
								issue={activeWorklogs[0]?.Issue as IIssue}
								onPause={handlePauseWorkLog}
								onFinish={handleFinishWorkLog}
								controlContext={activeIssueControlContext}
								showClock={true}
								handleFetchWorkableIssues={handleFetch}
							/>
						</Toolbar>
					</Hidden>
				</VisibilityControl>
				<Hidden mdDown>
					<VisibilityControl condition={isVisibleWorkLogBar}>
						<div className={classes.activeTask}>
							<WorklogStarter
								active
								isLoading={isLoading}
								issue={getIssue(activeWorklogs[0] ?? {}, issuesSelector_NEW)}
								onPause={handlePauseWorkLog}
								onFinish={handleFinishWorkLog}
								controlContext={activeIssueControlContext}
								showClock={true}
								handleFetchWorkableIssues={handleFetch}
							/>
						</div>
					</VisibilityControl>
					<Tooltip classes={{ popper: classes.tooltipWidth }} title={<HotkeysTooltip />}>
						<Button className={classes.menuButton} onClick={() => setOpenHotkeys(true)} ref={hotkeysRef}>
							<HelpOutlineIcon />
						</Button>
					</Tooltip>

					<Tooltip title={tString('GeneralToolTip.notifications')}>
						<Button
							className={clsx(
								classes.menuButton,
								countOfNotReadUserNotifications > 0 &&
									countOfNotReadUserNotifications < 10 &&
									classes.marginOneChar,
								countOfNotReadUserNotifications >= 10 &&
									countOfNotReadUserNotifications <= 99 &&
									classes.marginTwoChar,
								countOfNotReadUserNotifications > 99 && classes.marginThreeChar
							)}
							onClick={handleOpenNotificationsPopover}
							ref={notificationsRef}
						>
							<Badge
								max={99}
								badgeContent={countOfNotReadUserNotifications}
								color={Resources.GlobalResources.error}
								className={classes.badge}
							>
								<NotificationsIcon />
							</Badge>
						</Button>
					</Tooltip>

					<Tooltip title={tString('GeneralToolTip.profile')}>
						<Button onClick={() => history.push(Resources.links.settings)} className={classes.menuButton}>
							<Avatar src={taskerUser?.AvatarPath} className={classes.avatar} />
						</Button>
					</Tooltip>

					<LanguageButton logged={true} />
					<LogOutButton onLogOut={handleLogout} logOutText={tString('signOut')} />
				</Hidden>
			</Toolbar>
			<Hidden smUp>
				<VisibilityControl condition={isClockOpen && isVisibleWorkLogBar}>
					<Toolbar>
						<WorklogStarter
							active
							fullWidth
							isLoading={isLoading}
							issue={getIssue((activeWorklogs ?? [])[0] ?? {}, issuesSelector_NEW)}
							onPause={handlePauseWorkLog}
							onFinish={handleFinishWorkLog}
							controlContext={activeIssueControlContext}
							showClock={true}
							handleFetchWorkableIssues={handleFetch}
						/>
					</Toolbar>
				</VisibilityControl>
			</Hidden>
			<NotificationsPopover
				anchorEl={notificationsRef.current}
				open={openNotifications}
				closeModal={() => setOpenNotifications(false)}
			/>
			<HotkeysPopover closeModal={() => setOpenHotkeys(false)} open={openHotkeys} anchorEl={hotkeysRef.current} />
			<Popup
				closeOnBlur
				context={finalFormContext}
				className={classes.popup}
				title={tString('finalForm')}
				content={<EndOfDay isEdit onClose={onClose} onSave={onSaveForm} />}
			/>
			<TaskerLogoLoader isLoading={isLoading} />
		</AppBar>
	);
};

export default TopBar;

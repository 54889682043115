import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import Dialog from '@mui/material/Dialog';

import { attachment_show } from '@/store/actions';
import { Dispatch } from '@/store/types';

import { Props } from './model';
import { Resources } from './resources';
import { useStyles } from './styles';

export const PopupDialog = (props: Props): JSX.Element => {
	const { onClose, open, isImage, title, id } = props;
	const classes = useStyles();
	const [content, setContent] = useState<string | null>(null);
	const dispatch: Dispatch = useDispatch();

	//ToDo AD Dodać obsługę błędu oraz oczekiwania MC
	useEffect(() => {
		const fetchContent = async () => {
			try {
				dispatch(attachment_show(id)).then(response => {
					const blob = new Blob([response.data]);
					const url = URL.createObjectURL(blob);
					setContent(url);
				});
			} catch (error) {
				console.error('Error fetching content:', error);
			}
		};

		if (open) {
			fetchContent();
		}

		return () => {
			if (content) {
				URL.revokeObjectURL(content);
			}
		};
	}, [open]);

	return (
		<Dialog onClose={onClose} open={open}>
			<div className={classes.root}>
				{isImage ? (
					<div className={classes.imageContainer}>
						{content && (
							<img
								alt={title ?? Resources.GlobalResources.empty}
								className={classes.iframe}
								src={content}
							/>
						)}
					</div>
				) : (
					content && (
						<iframe
							title={title ?? Resources.GlobalResources.empty}
							className={classes.iframe}
							src={content}
						/>
					)
				)}
			</div>
		</Dialog>
	);
};

import {
	ICustomer,
	ICustomerChartData,
	IInvoice,
	IInvoiceItem,
	IInvoicePayment,
	IInvoiceSeries,
	IMeasureKind,
	IOrganization,
	IPage,
	IProject,
} from '@skillandchill/tasker-types';

import * as actionTypes from '@/store/actions';
import { ObjectAction } from '@/store/types';

const State = {
	customer: {} as ICustomer,
	chartData: {} as ICustomerChartData,
	chartDataCustomerId: 0 as number,
	projectList: [] as Partial<IProject>[],
	Invoices: {
		Page: {
			Count: 0,
			Data: [],
		} as IPage<IInvoice[]>,
		Details: {} as IInvoice,
		InvoiceSeries: [] as IInvoiceSeries[],
		InvoicePayment: [] as IInvoicePayment[],
		MeasureKind: [] as IMeasureKind[],
		InvoiceItemInEdit: {} as IInvoiceItem,
		Organization: {} as IOrganization,
	},
};
export type ICustomerDetailsState = typeof State;

const customerDetailsReducer = (state = State, action: ObjectAction): ICustomerDetailsState => {
	switch (action.type) {
		case actionTypes.CUSTOMER_FETCH_ONE: {
			return {
				...state,
				customer: action.customer,
			};
		}
		case actionTypes.CHART_CUSTOMER_GET: {
			return {
				...state,
				chartData: action.data,
			};
		}
		case actionTypes.CHART_CUSTOMER_ID_SET: {
			return {
				...state,
				chartDataCustomerId: action.data,
			};
		}
		case actionTypes.CHART_DATA_SET: {
			return {
				...state,
				chartData: action?.data,
			};
		}
		case actionTypes.CUSTOMER_INVOICES_DETAILS_CREATE: {
			return {
				...state,
				customer: action?.data,
			};
		}
		case actionTypes.UPDATE_INVOICE_DETAIL: {
			return {
				...state,
				customer: {
					...state?.customer,
					InvoiceDetails: action?.data,
				},
			};
		}
		case actionTypes.INVOICE_OPTIONS_GET: {
			return {
				...state,
				Invoices: {
					...state?.Invoices,
					MeasureKind: action?.data?.MeasureKind ?? [],
					InvoicePayment: action?.data?.InvoicePayment ?? [],
					InvoiceSeries: action?.data?.InvoiceSeries ?? [],
				},
			};
		}
		case actionTypes.INVOICE_ITEM_EDIT: {
			return {
				...state,
				Invoices: {
					...state?.Invoices,
					InvoiceItemInEdit: action?.data,
				},
			};
		}
		case actionTypes.INVOICE_ITEM_UPDATE: {
			const items =
				state?.Invoices?.Details?.InvoiceItem?.map(x => (x?.Id != action?.data?.Id ? x : action?.data)) ?? [];
			return {
				...state,
				Invoices: {
					...state?.Invoices,
					Details: {
						...state?.Invoices?.Details,
						InvoiceItem: items,
					},
					InvoiceItemInEdit: {} as IInvoiceItem,
				},
			};
		}
		case actionTypes.INVOICE_ITEM_ADD:
		case actionTypes.INVOICE_ITEM_CREATE: {
			let items = state?.Invoices?.Details?.InvoiceItem ?? [];
			items = items.filter(x => x?.Id != action?.data?.Id);
			items.push(action?.data);

			return {
				...state,
				Invoices: {
					...state?.Invoices,
					Details: {
						...state?.Invoices?.Details,
						InvoiceItem: items,
					},
					InvoiceItemInEdit: {} as IInvoiceItem,
				},
			};
		}
		case actionTypes.INVOICE_MODAL_RESET: {
			return {
				...state,
				Invoices: {
					...State.Invoices,
					Page: state?.Invoices?.Page,
				},
			};
		}
		case actionTypes.INVOICE_SERIES_UPDATE: {
			const items =
				state?.Invoices?.InvoiceSeries?.map(x => (x?.Id != action?.data?.Id ? x : action?.data)) ?? [];
			return {
				...state,
				Invoices: {
					...state.Invoices,
					InvoiceSeries: items,
				},
			};
		}
		case actionTypes.INVOICE_SERIES_CREATE: {
			const items = state?.Invoices?.InvoiceSeries ?? [];
			items.push(action?.data);

			return {
				...state,
				Invoices: {
					...state.Invoices,
					InvoiceSeries: items,
				},
			};
		}
		case actionTypes.INVOICE_MODAL_CREATE_LOCAL: {
			return {
				...state,
				Invoices: {
					...state?.Invoices,
					Details: {
						...state.Invoices.Details,
						...action?.data,
					},
				},
			};
		}
		case actionTypes.INVOICE_FETCH_PAGE: {
			return {
				...state,
				Invoices: {
					...state?.Invoices,
					Page: action?.data,
				},
			};
		}
		case actionTypes.INVOICE_FETCH_ONE: {
			return {
				...state,
				Invoices: {
					...state?.Invoices,
					Details: action?.data,
				},
			};
		}
		case actionTypes.GET_ORGANIZATION: {
			return {
				...state,
				Invoices: {
					...state?.Invoices,
					Organization: action?.organization,
				},
			};
		}
		default: {
			return state;
		}
	}
};

export default customerDetailsReducer;

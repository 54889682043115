import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	px: 'px',
	issueId: '?issueId=',
	virtual: 'virtual' as const,
	draggableIdDefault: '-1',
	mockTileKey: 'mockTileKey1',
	innerItemKey: 'innerItemKey',
	viewPortIncrease: 500,
	smooth: 'smooth' as const,
	start: 'start',
	swimlanesContainer: 'swimlanesContainer',
	swimlanesId: 'swimlane:id:',
	responseParam: 'MapTagToFieldDefinition',
	chooseTagPopupContext: 'chooseTagPopupContext',
	...GlobalResources,
};

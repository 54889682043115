import { AsyncAction } from '@skillchill/redux-promisify';

import {
	IInvoiceDetails,
	IOrganization,
	IOrganizationCreate,
	IOrganizationStructure,
	IPosition,
	IStaticEntity,
	IWorklogTimer,
} from '@skillandchill/tasker-types';
import { ICategoryPosition, ITaskerUser } from '@skillandchill/tasker-types/dist/types';
import { IPositionRole } from '@skillandchill/tasker-types/dist/types/dataBaseTypes/positionRole';
import { Modifiable } from '@skillandchill/tasker-widgets2/dist/utils/types/global';

import { IJson } from '@/store/ITypes';
import { Action, ObjectAction } from '@/store/types';
import axios from '@/utils/axios';

export const TENANT_FETCH_REGISTER_INFO = 'TENANT_FETCH_REGISTER_INFO';
export const GET_ORGANIZATION = 'GET_ORGANIZATION';
export const ORGANIZATION_FETCH_CLOCK_DATA = 'ORGANIZATION_FETCH_CLOCK_DATA';
export const ORGANIZATION_STRUCTURE_FETCH = 'ORGANIZATION_STRUCTURE_FETCH';
export const ORGANIZATION_STRUCTURE_CREATE = 'ORGANIZATION_STRUCTURE_CREATE';
export const ORGANIZATION_STRUCTURE_UPDATE = 'ORGANIZATION_STRUCTURE_UPDATE';
export const ORGANIZATION_STRUCTURE_DELETE = 'ORGANIZATION_STRUCTURE_DELETE';
export const POSITION_CREATE = 'POSITION_CREATE';
export const POSITION_UPDATE = 'POSITION_UPDATE';
export const POSITION_DELETE = 'POSITION_DELETE';
export const CATEGORY_POSITIONS_FETCH = 'CATEGORY_POSITIONS_FETCH';
export const CATEGORY_POSITIONS_CREATE = 'CATEGORY_POSITIONS_CREATE';
export const CATEGORY_POSITIONS_UPDATE = 'CATEGORY_POSITIONS_UPDATE';
export const CATEGORY_POSITIONS_DELETE = 'CATEGORY_POSITIONS_DELETE';
export const POSITION_ROLES_FETCH = 'POSITION_ROLES_FETCH';
export const TENANT_ADD_ADDRESS = 'TENANT_ADD_ADDRESS';
export const USER_REMOVE_FROM_ORGANIZATION = 'USER_REMOVE_FROM_ORGANIZATION';
export const NEED_REFRESH = 'NEED_REFRESH';
export const ORGANIZATION_STRUCTURE_DATA_FETCH = 'ORGANIZATION_STRUCTURE_DATA_FETCH';
export const FETCH_MY_ORGANIZATIONS = 'FETCH_MY_ORGANIZATIONS';

const PATH_TENANT_CLOCK_DATA = '/tenant/clock/data';

const basePath = 'Organization';

export interface TenantClockData extends Modifiable {
	toDoTagId?: number | null;
	inProgressTagId?: number | null;
	finishedTagId?: number | null;
}

export function needRefresh(): ObjectAction {
	return {
		type: NEED_REFRESH,
	};
}

function _fetchRegisterInfo(info: { avatar: string; name: string }): ObjectAction {
	return {
		type: TENANT_FETCH_REGISTER_INFO,
		info,
	};
}

function _fetchClockData(organizationClockData: TenantClockData): ObjectAction {
	return {
		type: ORGANIZATION_FETCH_CLOCK_DATA,
		organizationClockData,
	};
}
function _addAddress(data: IInvoiceDetails): ObjectAction {
	return {
		type: TENANT_ADD_ADDRESS,
		data,
	};
}
export function _fetchOrganization(organization: Partial<IOrganization>): Action {
	return {
		type: GET_ORGANIZATION,
		organization,
	};
}

export function _fetchWorklogData(organizationWorklogTimer: IWorklogTimer[]): ObjectAction {
	return {
		type: ORGANIZATION_FETCH_CLOCK_DATA,
		organizationWorklogTimer,
	};
}

export function _fetchOrganizationStructure(organizationStructure: IOrganizationStructure): ObjectAction {
	return {
		type: ORGANIZATION_STRUCTURE_FETCH,
		organizationStructure,
	};
}
export function _fetchOrganizationStructureData(data: IOrganizationStructureData): ObjectAction {
	return {
		type: ORGANIZATION_STRUCTURE_DATA_FETCH,
		data,
	};
}

export function _createOrganizationStructure(organizationStructure: IOrganizationStructure): ObjectAction {
	return {
		type: ORGANIZATION_STRUCTURE_CREATE,
		organizationStructure,
	};
}

export function _updateOrganizationStructure(organizationStructure: IOrganizationStructure): ObjectAction {
	return {
		type: ORGANIZATION_STRUCTURE_UPDATE,
		organizationStructure,
	};
}

export function _deleteOrganizationStructure(id: number): ObjectAction {
	return {
		type: ORGANIZATION_STRUCTURE_DELETE,
		id,
	};
}

export function _createPosition(position: IPosition): ObjectAction {
	return {
		type: POSITION_CREATE,
		position,
	};
}

export function _updatePosition(position: IPosition): ObjectAction {
	return {
		type: POSITION_UPDATE,
		position,
	};
}

export function _deletePosition(id: number): ObjectAction {
	return {
		type: POSITION_DELETE,
		id,
	};
}

export function _getCategoryPositions(categoryPositions: ICategoryPosition[]): ObjectAction {
	return {
		type: CATEGORY_POSITIONS_FETCH,
		categoryPositions,
	};
}

export function _createCategoryPositions(categoryPosition: ICategoryPosition): ObjectAction {
	return {
		type: CATEGORY_POSITIONS_CREATE,
		categoryPosition,
	};
}

export function _updateCategoryPositions(categoryPosition: ICategoryPosition): ObjectAction {
	return {
		type: CATEGORY_POSITIONS_UPDATE,
		categoryPosition,
	};
}

export function _deleteCategoryPositions(id: number): ObjectAction {
	return {
		type: CATEGORY_POSITIONS_DELETE,
		id,
	};
}

export function _getPositionRoles(positionRoles: IStaticEntity[]): ObjectAction {
	return {
		type: POSITION_ROLES_FETCH,
		positionRoles,
	};
}
export function _myOrg(organizations: IOrganization[]): ObjectAction {
	return {
		type: FETCH_MY_ORGANIZATIONS,
		data: organizations,
	};
}

export function organizationUsersUpdated(data: IOrganization[]): ObjectAction {
	return {
		type: USER_REMOVE_FROM_ORGANIZATION,
		data,
	};
}

export function tenant_fetchRegisterInfo(tenantToken: string): AsyncAction {
	return function(context, dispatch) {
		context.then(json => dispatch(_fetchRegisterInfo((json as IJson).data)));
		return axios().get('/tenant/register', {
			params: {
				token: tenantToken,
			},
		});
	};
}

export function tenantClock_fetchData(): AsyncAction {
	return function(context, dispatch) {
		context.then(data => dispatch(_fetchClockData((data as IJson).data as TenantClockData)));
		return axios().get(PATH_TENANT_CLOCK_DATA);
	};
}

export function get_organization(id: number): AsyncAction {
	return function(context, dispatch) {
		context.then(data => dispatch(_fetchOrganization((data as IJson).data)));
		return axios().get(`${basePath}/${id}`);
	};
}

export function update_organization(id: number, organization: Partial<IOrganization>): AsyncAction {
	return function(context, dispatch) {
		context.then(data => dispatch(_fetchOrganization((data as IJson).data)));
		return axios().patch(`${basePath}/${id}`, organization);
	};
}

export function fetch_organization_structures_data(): AsyncAction {
	return function(context, dispatch) {
		context.then(data => dispatch(_fetchOrganizationStructureData((data as IJson).data)));
		return axios().get(`OrganizationStructure/data`);
	};
}

export function create_organization_structure(data: Partial<IOrganizationStructure>): AsyncAction {
	return function(context, dispatch) {
		context.then(data => dispatch(_createOrganizationStructure((data as IJson).data)));
		return axios().post(`OrganizationStructure`, { data });
	};
}

export function update_organization_structure(data: Partial<IOrganizationStructure>): AsyncAction {
	return function(context, dispatch) {
		context.then(data => dispatch(_updateOrganizationStructure((data as IJson).data)));
		return axios().patch(`OrganizationStructure`, { data });
	};
}

export function delete_organization_structure(id: number): AsyncAction {
	return function(context, dispatch) {
		context.then(() => dispatch(_deleteOrganizationStructure(id)));
		return axios().delete(`OrganizationStructure/${id}`);
	};
}

export function delete_category_position(id: number): AsyncAction {
	return function(context, dispatch) {
		context.then(() => dispatch(_deleteCategoryPositions(id)));
		return axios().delete(`CategoryPosition/${id}`);
	};
}

// dodać update zamiast create bo dadaje a nie aktualizuje

export function create_position(data: Partial<IPosition>): AsyncAction {
	return function(context, dispatch) {
		context.then(data => dispatch(_createPosition((data as IJson).data)));
		return axios().post(`Position`, { data });
	};
}

export function update_position(data: Partial<IPosition>): AsyncAction {
	return function(context, dispatch) {
		context.then(data => dispatch(_updatePosition((data as IJson).data)));
		return axios().patch(`Position`, { data });
	};
}

export function delete_position(id: number): AsyncAction {
	return function(context, dispatch) {
		context.then(() => dispatch(_deletePosition(id)));
		return axios().delete(`Position/${id}`);
	};
}

export function create_category_positions(data: Partial<ICategoryPosition>): AsyncAction {
	return function(context, dispatch) {
		context.then(data => dispatch(_createCategoryPositions((data as IJson).data)));
		return axios().post(`CategoryPosition`, { data });
	};
}

export function update_category_positions(data: Partial<ICategoryPosition>): AsyncAction {
	return function(context, dispatch) {
		context.then(data => dispatch(_updateCategoryPositions((data as IJson).data)));
		return axios().patch(`CategoryPosition`, { data });
	};
}

export function create_organization(data: IOrganizationCreate): AsyncAction {
	return function(context, dispatch) {
		context.then(data => dispatch(_createOrganizationStructure((data as IJson).data)));
		return axios().post(`${basePath}`, { data });
	};
}

export function tenant_addressAdd(addressDetails: Partial<IInvoiceDetails>): AsyncAction {
	return function(context, dispatch) {
		context.then(data => dispatch(_addAddress((data as IJson).data)));
		return axios().post(`${basePath}/Address`, { ...addressDetails });
	};
}

export function removeTaskerUserFromOrganization(taskerUserId: number, organizationId: number): AsyncAction {
	return function(context, dispatch) {
		context.then(data => dispatch(organizationUsersUpdated((data as IJson).data)));
		return axios().post(`${basePath}/${organizationId}/${taskerUserId}`);
	};
}

export interface IOrganizationStructureData {
	categoryPositions: ICategoryPosition[];
	organizationStructure: IOrganizationStructure;
	positionRoles: IPositionRole[];
	taskerUsers: Partial<ITaskerUser>[];
}

export function organization_fetch_my(): AsyncAction {
	return function(context, dispatch) {
		context.then(data => dispatch(_myOrg((data as IJson).data)));
		return axios().get(`${basePath}/options`);
	};
}

import { Buffer } from 'buffer';

export const encryptOrigin = async (): Promise<string> => {
	return await encrypt(window.location.href);
};

const encrypt = async (value: string): Promise<string> => {
	const cryptos = window.crypto ?? crypto;
	const encryptionKey: string = process.env.REACT_APP_ENCRYPTION_KEY as string;
	const decodedEncryptionKey = Buffer.from(encryptionKey, 'base64');
	if (cryptos?.subtle?.importKey) {
		const key = await cryptos?.subtle?.importKey('raw', decodedEncryptionKey, { name: 'AES-GCM' }, false, [
			'encrypt',
		]);

		const nonce = cryptos.getRandomValues(new Uint8Array(12));
		const encodedValue = new TextEncoder().encode(value);
		const cipherText = await cryptos.subtle.encrypt(
			{
				name: 'AES-GCM',
				iv: nonce,
			},
			key,
			encodedValue
		);

		const result = new Uint8Array(nonce?.length + new Uint8Array(cipherText)?.length);
		result.set(nonce);
		result.set(new Uint8Array(cipherText), nonce.length);
		return Buffer.from(result).toString('base64');
	} else {
		console.error('Crypto API not supported.');
		return '';
	}
};

import { t } from 'i18next';

import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	defaultColor: 'FFFFFF' as const,
	anchorOrigin: {
		vertical: 'center',
		horizontal: 'center',
	} as const,
	simplePopover: 'simple-popover',
	...GlobalResources,
};

export const Translation = {
	pl: {
		Kanban: {
			KanbanBoard: {
				KanbanCreator: {
					CreationPopupContent: {
						SwimlaneConfiguration: {
							SwimlaneTile: {
								name: 'Nazwa',
								generalSwimlane:
									'Ustaw swimlane jako główny. Będą trafiały do niego wszystkie nowe zgłoszenia',
								delete: 'Usuń swimlane',
								deleteConfirmationMessage: 'Czy na pewno chcesz usunąć ten swimlane?',
								Errors: {
									nameSwimlaneErrorMessage: '^Nazwa powinna zawierać od 1 do 50 znaków!',
								},
								cantDeleteDefaultSwimlane: `Nie możesz usunąć głównego swimlane'a`,
							},
						},
					},
				},
			},
		},
	},
	en: {
		Kanban: {
			KanbanBoard: {
				KanbanCreator: {
					CreationPopupContent: {
						SwimlaneConfiguration: {
							SwimlaneTile: {
								name: 'Name',
								generalSwimlane: 'Set swiline as general. All new issues will apera here',
								delete: 'Delete swimlane',
								deleteConfirmationMessage: 'Are you sure, you want delete this swimeline?',
								Errors: {
									nameSwimlaneErrorMessage: '^The name should contain between 1 and 50 characters!',
								},
								cantDeleteDefaultSwimlane: 'You cant delete default swimlane',
							},
						},
					},
				},
			},
		},
	},
};

export const getValidationRules = () => {
	return {
		Name: {
			presence: {
				maximum: 50,
				allowEmpty: false,
				message: t(
					'Kanban.KanbanBoard.KanbanCreator.CreationPopupContent.SwimlaneConfiguration.SwimlaneTile.Errors.nameSwimlaneErrorMessage'
				),
			},
		},
	};
};

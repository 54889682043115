import clsx from 'clsx';
import { useContext, useMemo } from 'react';
import { useSelector } from 'react-redux';

import AddIcon from '@mui/icons-material/Add';
import { Typography } from '@mui/material';

import { EFieldDefinitionType, IDictionaryItem } from '@skillandchill/tasker-types';
import { CustomSelect, VisibilityControl } from '@skillandchill/tasker-widgets2';

import { IReducerState } from '@/store/reducers/types';

import { IssueFormSectionsWidgetContext } from '../..';
import { IssuePropertyTitle } from '../IssuePropertyTitle';
import { useInitialAttributeDefinitionValues } from '../utils/useInitialAttributeDefinitionValues';

import { DictionaryItemComponent } from './DictionaryItemComponent';
import { Props } from './model';
import { MultiSelectValueLabelCustom } from './MultiSelectValueLabelCustom';
import { CustomOption } from './Option';
import { Resources } from './resources';
import { SingleValueCustom } from './SingleValueCustom';
import { useStyles } from './styles';
import { getDictionaryValue } from './utils';
import { ValueContainer } from './ValueContainer';

export const DictionaryIssueProperty = (props: Props) => {
	const { issueFormSectionToIssueField, inEdit } = props;
	const classes = useStyles();
	const { handleChange, state } = useContext(IssueFormSectionsWidgetContext);

	const isDarkMode = useSelector((state: IReducerState) => state?.Session?.darkMode);

	const AttributeDefinitionId = issueFormSectionToIssueField?.AttributeDefinitionId as number;

	const isMultiValue = issueFormSectionToIssueField?.AttributeDefinition?.IsMultiValue;

	const initial = useInitialAttributeDefinitionValues(issueFormSectionToIssueField);

	const initialDictionaryItems = initial?.map(fieldValue => fieldValue?.ValueDictionaryItem) as IDictionaryItem[];

	const value = useMemo(() => {
		const newValues =
			state &&
			(state?.[EFieldDefinitionType.Dictionary]?.[AttributeDefinitionId] as
				| IDictionaryItem
				| IDictionaryItem[]
				| undefined);

		const isNewValueSet = !!newValues;

		if (isNewValueSet) {
			return newValues;
		}

		const initialValues = initial?.map(fieldValue => fieldValue?.ValueDictionaryItem) as IDictionaryItem[];

		if (isMultiValue) return initialValues ?? [];
		else return initialValues?.length ? initialValues[0] : undefined;
	}, [state, AttributeDefinitionId]);

	const getCustomComponents = () => {
		if (issueFormSectionToIssueField?.AttributeDefinition?.IsMultiValue) {
			return {
				ValueContainer: ValueContainer,
				MultiValueLabel: MultiSelectValueLabelCustom,
				Option: CustomOption,
			};
		} else {
			return {
				SingleValue: SingleValueCustom,
				Option: CustomOption,
			};
		}
	};

	const canClearSelect = useMemo(() => {
		return !issueFormSectionToIssueField?.AttributeDefinition?.IsMandatory && value;
	}, [issueFormSectionToIssueField, initialDictionaryItems, value]);

	const getArgs = () => {
		return {
			['components']: {
				...getCustomComponents(),
			},
			valuesLimit: 1,
			menuPosition: Resources.fixed,
			isClearable: canClearSelect,
			isSearchable: false,
			closeMenuOnSelect: !issueFormSectionToIssueField?.AttributeDefinition?.IsMultiValue,
		};
	};

	return (
		<div className={clsx(classes.flexYCenter, classes.container)}>
			<IssuePropertyTitle {...props} />

			<div className={clsx(classes.flexGrow, classes.selectContainer)}>
				<CustomSelect
					isDarkMode={isDarkMode}
					inEdit={inEdit}
					label=""
					className={clsx(
						!!issueFormSectionToIssueField?.AttributeDefinition?.IsMultiValue && classes.multiValueContainer
					)}
					items={
						(issueFormSectionToIssueField?.AttributeDefinition?.Dictionary
							?.DictionaryItem as IDictionaryItem[]) ?? []
					}
					getName={getDictionaryValue}
					getKey={(dictionaryItem: IDictionaryItem) => dictionaryItem?.Id}
					values={value}
					onChange={val => handleChange && handleChange(val, issueFormSectionToIssueField)}
					args={getArgs()}
					isMulti={!!issueFormSectionToIssueField?.AttributeDefinition?.IsMultiValue}
					canSetInputInNonEdit
					nonEditComponent={
						<div className={clsx(classes.flexYCenter, classes.valueContainer)}>
							<VisibilityControl
								condition={!!initialDictionaryItems?.length}
								alternative={<div className={clsx(classes.tag)}>-</div>}
							>
								{initialDictionaryItems?.slice(0, 2).map((dictionaryItem, i) => (
									<DictionaryItemComponent
										dictionaryItem={dictionaryItem}
										classNameText={classes.dictionaryItemText}
										className={clsx(
											i > 0 ? classes.dictionaryItemContainerMargin : undefined,
											classes.dictionaryItemContainer
										)}
										key={i}
										showComma={true}
									/>
								))}
								<VisibilityControl condition={initialDictionaryItems?.length > 2}>
									<div className={clsx(classes.flexYCenter, classes.counter)}>
										<AddIcon className={classes.icon} />
										<Typography className={classes.counterText}>
											{initialDictionaryItems?.length - 2}
										</Typography>
									</div>
								</VisibilityControl>
							</VisibilityControl>
						</div>
					}
				/>
			</div>
		</div>
	);
};

import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { matchRoutes } from 'react-router-config';
import { useLocation } from 'react-router-dom';

import { Routes, SecureRouteConfig } from '@/routes';
import { session_loginWithToken } from '@/store/actions';
import { IReducerState } from '@/store/reducers/types';
import { Dispatch } from '@/store/types';
import { CheckRoles } from '@/utils/permissions';

import { Props } from './model';
import { Resources } from './resources';

export const SecureRouter = (props: Props): JSX.Element => {
	const history = useHistory();
	const location = useLocation();
	const dispatch: Dispatch = useDispatch();
	const [login, setLogin] = useState<boolean>(false);
	let firstLoad = false;

	const session = useSelector((state: IReducerState) => state.Session);
	const mustChange: boolean = useSelector((state: IReducerState) => state?.Session.mustChangePassword);

	useEffect(() => {
		if (location?.pathname === Resources.changePasswordHash) return;
		if (mustChange === true) history.push(Resources.changePasswordHash);
	}, [mustChange, location]);

	useEffect(() => {
		firstLoad = true;
		setLogin(true);
		const startLocation = location;
		history.replace('/auth/loading');
		dispatch(session_loginWithToken())
			.then(() => {
				history.push(startLocation);
			})
			.catch(() => {
				const routes = matchRoutes(Routes, startLocation.pathname);
				if (routes.reduce((x, y) => (y.route.secured ?? false) || x, false) && !session.isLoggedIn) {
					history.replace('/auth/login');
				} else history.push(startLocation);
			})
			.finally(() => {
				setLogin(false);
			});
	}, []);

	useEffect(() => {
		// If user is logged in or it's the first load, return
		if (login || firstLoad) return;

		// If user appears to be on the error page, return because we don't want to trigger infinite loop
		const errorPages = ['/errors/error-401', '/errors/error-403', '/errors/error-404', '/errors/error-500'];
		if (errorPages.includes(location.pathname)) return;

		// Route protection, redirects user to login page if doesn't have necessary permissions
		const routes = matchRoutes(Routes, location.pathname);
		if (routes.reduce((x, y) => (y.route.secured ?? false) || x, false) && !session.isLoggedIn) {
			history.replace('/auth/login');
			return;
		}

		if (!mustChange && location.pathname === Resources.changePasswordHash) history.push('/activity');

		// Check if user has necessary permission
		const route: SecureRouteConfig = routes[routes.length - 1].route;
		if (!CheckRoles(session.permissions, route.permission)) {
			history.push('/errors/error-401');
		}
	}, [location, session.isLoggedIn]);
	return <div>{props.children}</div>;
};

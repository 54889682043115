export const Indexes = {
	droppableId: 'droppable',
	kanbanTagsId: 'kanbanTagsNew',
	kanbanColumnsId: 'kanbanColumn',
	tagsInCollapseId: 'tagsInCollapse',
	kanbanFreeTagsId: 'kanbanFreeTags',
	ChipId: 'Chip',
	classNameIndex: 'front',
	droppableTagId: 'droppableTagIdKey',
	draggableTaskId: 'draggableTaskIdKey',
	KanbanTileTag: 'KanbanTileTag',
	swimlane: 'swimlane',
	swimlaneTile: 'swimlaneTile',
	TagChip: 'TagChip',
};

import { Dispatch } from 'react';

export interface Props {
	selectedTab: EIssueModalDetailsPageTabs;
	setSelectedTab: Dispatch<EIssueModalDetailsPageTabs>;
}

export enum EIssueModalDetailsPageTabs {
	Description = 'Description',
	Comments = 'Comments',
	Attachments = 'Attachments',
}

export interface ITab {
	icon?: JSX.Element;
	label: string;
	value: EIssueModalDetailsPageTabs;
	disableNew: boolean;
}

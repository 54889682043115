import { Translation as Attachment } from './Options/Attachment/resources';
import { Translation as Buttons } from './Options/Buttons/resources';
import { Translation as OptionsComments } from './Options/Comments/resources';
import { Translation as IsDeleted } from './Options/IsDeleted/resources';
import { Translation as QuickFilters } from './Options/QuickFilters/resources';
import { Translation as TagFilterIcons } from './Options/TagFilterManage/TagFilter/Icons/resources';
import { Translation as TagFilter } from './Options/TagFilterManage/TagFilter/resources';
import { Translation as TagFilterSimplified } from './Options/TagFilterManage/TagFilterSimplified/resources';
import { Translation as Filters } from './resources';
import { Translation as Tags } from './Tags/resources';

export const IssueListFiltersTranslationPL = [
	Filters.pl,
	Buttons.pl,
	OptionsComments.pl,
	QuickFilters.pl,
	TagFilter.pl,
	TagFilterSimplified.pl,
	TagFilterIcons.pl,
	Tags.pl,
	IsDeleted.pl,
	Attachment.pl,
];

export const IssueListFiltersTranslationEN = [
	Filters.en,
	Buttons.en,
	Tags.en,
	QuickFilters.en,
	TagFilterIcons.en,
	TagFilter.en,
	OptionsComments.en,
	IsDeleted.en,
	Attachment.en,
];

import clsx from 'clsx';
import { t } from 'i18next';

import AssignmentIcon from '@mui/icons-material/Assignment';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import LanIcon from '@mui/icons-material/Lan';
import ListIcon from '@mui/icons-material/List';
import { ClassNameMap } from '@mui/styles';

import { EIssueModalTabs } from '@skillandchill/tasker-types';

import { ITab } from './model';
import { Resources } from './resources';

export const getTabs = (classes: ClassNameMap<'tabIcon' | 'iconRotated'>, active?: boolean): ITab[] => {
	const activeTabs: ITab[] = [
		{
			icon: <AssignmentIcon className={classes.tabIcon} />,
			label: t('IssueModal.IssueModalContent.Tabs.detailsTab'),
			value: EIssueModalTabs.details,
			disableNew: false,
		},
	];
	if (active === true) return activeTabs;

	const nonActiveTabs: ITab[] = [
		{
			icon: <LanIcon className={clsx(classes.tabIcon, classes.iconRotated)} />,
			label: t('IssueModal.IssueModalContent.Tabs.subTasksTab'),
			value: EIssueModalTabs.childTasks,
			disableNew: true,
		},
		{
			icon: <LanIcon className={classes.tabIcon} />,
			label: t('IssueModal.IssueModalContent.Tabs.higherTasksTab'),
			value: EIssueModalTabs.parentTasks,
			disableNew: true,
		},
		{
			icon: <ListIcon className={classes.tabIcon} />,
			label: t('IssueModal.IssueModalContent.Tabs.equivalentTab'),
			value: EIssueModalTabs.equivalentTasks,
			disableNew: true,
		},
		{
			icon: <FormatListNumberedIcon className={classes.tabIcon} />,
			label: t('IssueModal.IssueModalContent.Tabs.worklogsTab'),
			value: EIssueModalTabs.worklogs,
			disableNew: true,
		},
	];
	if (active === false) return nonActiveTabs;

	return [...activeTabs, ...nonActiveTabs];
};

export const getClasses = (
	tab: EIssueModalTabs,
	classes: ClassNameMap<'tabSelected' | 'tabNotSelected' | 'tabIconSelected' | 'tab'>,
	selectedTab?: EIssueModalTabs
) => {
	switch (tab) {
		case EIssueModalTabs.details:
			return clsx(
				classes.tab,
				selectedTab === EIssueModalTabs.details ? classes.tabSelected : classes.tabNotSelected,
				selectedTab === EIssueModalTabs.details && classes.tabIconSelected
			);
		case EIssueModalTabs.childTasks:
			return clsx(
				classes.tab,
				selectedTab === EIssueModalTabs.childTasks ? classes.tabSelected : classes.tabNotSelected
			);
		case EIssueModalTabs.parentTasks:
			return clsx(
				classes.tab,
				selectedTab === EIssueModalTabs.parentTasks ? classes.tabSelected : classes.tabNotSelected
			);
		case EIssueModalTabs.equivalentTasks:
			return clsx(
				classes.tab,
				selectedTab === EIssueModalTabs.equivalentTasks ? classes.tabSelected : classes.tabNotSelected
			);
		case EIssueModalTabs.worklogs:
			return clsx(
				classes.tab,
				selectedTab === EIssueModalTabs.worklogs ? classes.tabSelected : classes.tabNotSelected
			);
		default:
			return Resources.GlobalResources.empty;
	}
};

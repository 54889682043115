import clsx from 'clsx';
import React, { useContext, useEffect, useState } from 'react';

import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';

import { useTrans } from '@/utils/hooks/useTrans';

import { issueModalDataContext } from 'view/IssueModal';

import { EIssueModalDetailsPageTabs, ITab, Props } from './model';
import { Resources } from './resources';
import { useStyles } from './styles';
import { getTabs } from './utils';

export const IssueDetailsTabs = (props: Props) => {
	const { selectedTab, setSelectedTab } = props;
	const classes = useStyles();
	const { t } = useTrans('IssueModal.IssueModalContent.IssueDetailsPage.Tabs');
	const { isEdittingIssue } = useContext(issueModalDataContext);

	const [tabs, setState] = useState<ITab[]>(getTabs());

	useEffect(() => {
		setSelectedTab(EIssueModalDetailsPageTabs.Description);
	}, []);

	useEffect(() => {
		setState(getTabs());
	}, [t]);

	const handleChange = (event: React.SyntheticEvent, newTab: EIssueModalDetailsPageTabs) => {
		setSelectedTab(newTab);
	};

	return (
		<TabContext value={selectedTab}>
			<Box sx={{ borderBottom: 1, borderColor: Resources.divider }}>
				<TabList
					onChange={handleChange}
					className={classes.tabList}
					textColor={Resources.GlobalResources.inherit}
				>
					{tabs?.map((x, index) => (
						<Tab
							key={Resources.getKey(index)}
							className={clsx(
								classes.tab,
								!isEdittingIssue && !!x?.disableNew && classes.hidden,
								selectedTab?.trim() === x?.value?.trim() ? classes.tabSelected : classes.tabNotSelected
							)}
							label={
								<div className={classes.flexYCenter}>
									<span>{x?.label}</span>
								</div>
							}
							value={x?.value}
						/>
					))}
				</TabList>
			</Box>
		</TabContext>
	);
};

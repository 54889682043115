import {
	EBoolean,
	EParameter,
	IOrganization,
	IPasswordPolicy,
	ITaskerUser,
	ITaskerUserSetting,
} from '@skillandchill/tasker-types';
import { IVersion } from '@skillandchill/tasker-types/dist/types/dataBaseTypes/version';
import { isMobile } from '@skillandchill/tasker-widgets2/dist/utils';

import * as actionTypes from '@/store/actions';
import { ObjectAction } from '@/store/types';

export const sessionInitialState = {
	customerFiltersNeedReset: 0,
	isLoggedIn: false as boolean,
	rememberMe: true as boolean,
	permissions: [] as number[],
	profiles: [] as Partial<ITaskerUser>[],
	profile: undefined as Partial<ITaskerUser> | undefined,
	changeToken: '' as string,
	chosenOrganization: (undefined as unknown) as IOrganization,
	taskerUser: {} as ITaskerUser,
	isMobile: false as boolean,
	language: '' as string,
	languageId: (undefined as unknown) as number,
	hasOrgCustomTranslation: false,
	customTransaltionKey: '' as string,
	loggedIntoOrganizationId: (undefined as unknown) as number,
	preferences: [] as ITaskerUserSetting[],
	darkMode: false as boolean,
	loadingQueue: [] as string[],
	isLoading: false as boolean,
	version: {} as IVersion,
	userHasEndedDay: EBoolean.NotSpecified,
	sharedPasswordPolicies: [] as Partial<IPasswordPolicy>[],
	mustChangePassword: false as boolean,
};
export type ISessionState = typeof sessionInitialState;

const sessionReducer = (state = sessionInitialState, action: ObjectAction): ISessionState => {
	switch (action.type) {
		case '@@INIT': {
			return {
				...sessionInitialState,
				...state,
			};
		}
		case actionTypes.SESSION_RESET: {
			return {
				...state,
				permissions: [],
			};
		}
		case actionTypes.SESSION_START_LOGIN: {
			return {
				...state,
				profiles: action.profiles,
			};
		}
		case actionTypes.SESSION_SET_LOCAL_AVATAR: {
			return {
				...state,
				profile: ({ ...state.profile, avatar: action.avatar } as unknown) as Partial<ITaskerUser>,
			};
		}

		case actionTypes.SESSION_RESET_PASSWORD_RECEIVE:
		case actionTypes.SESSION_LOGOUT: {
			return {
				...sessionInitialState,
			};
		}
		case actionTypes.SESSION_UPDATE_AUTH_INFO:
		case actionTypes.SESSION_FINISH_LOGIN: {
			return {
				...state,
				taskerUser: action?.profileData,
				permissions: action?.permissions,
				language: action?.language ?? '',
				languageId: action?.languageId ?? undefined,
				chosenOrganization: action?.chosenOrganization ?? undefined,
				loggedIntoOrganizationId: action?.chosenOrganization?.Id ?? undefined,
				hasOrgCustomTranslation: action?.translate?.hasOrgCustomTranslation ?? undefined,
				customTransaltionKey: action?.translate?.customTranslationOrgKey ?? '',
				profile: { ...action?.profileData, avatar: action?.profileData?.AvatarPath },
				isLoggedIn: true,
				profiles: [action.profileData],
				preferences: action?.preferences,
				darkMode: Boolean(
					action?.preferences?.find(
						(pref: ITaskerUserSetting) => pref?.ParameterId == EParameter.User_DarkModeOn
					)?.ValueBoolean
				),
				isMobile: isMobile(),
			};
		}
		case actionTypes.SESSION_REMEMBER_ME_SET: {
			return {
				...state,
				rememberMe: action.value,
			};
		}
		case actionTypes.SESSION_CHECK_PASSWORD_RECOVERY: {
			return {
				...state,
				changeToken: action.recoverToken,
			};
		}
		case actionTypes.USER_UPDATE_SESSION: {
			return {
				...state,
				taskerUser: { ...state.taskerUser, ...action.taskerUser },
			};
		}
		case actionTypes.SESSION_SET_IS_SMALL_DEVICE: {
			return {
				...state,
				isMobile: action.isMobile,
			};
		}
		case actionTypes.SESSION_SET_IS_LOADING_V2: {
			return {
				...state,
				isLoading: action?.data,
			};
		}
		case actionTypes.SESSION_SET_LANGUAGE: {
			return {
				...state,
				language: action?.language ?? {},
				languageId: action?.languageId,
			};
		}
		case actionTypes.TASKERUSERSETTIGNS_UPDATE_PREFERENCES: {
			const simplifiedFilters = action?.data?.ParameterId === EParameter.User_UISimplifyFilters;

			const refresher = state?.customerFiltersNeedReset;

			return {
				...state,
				customerFiltersNeedReset: simplifiedFilters ? refresher + 1 : refresher,
				preferences: state?.preferences?.map(x =>
					x?.ParameterId == action?.data?.ParameterId ? action?.data : x
				),
				darkMode:
					action?.data?.ParameterId == EParameter.User_DarkModeOn
						? action?.data?.ValueBoolean
						: state?.darkMode,
			};
		}
		case actionTypes.CHECKLIST_VALUE_CREATE:
		case actionTypes.SET_COMPLETED_DAY: {
			return {
				...state,
				userHasEndedDay: action?.data?.userHasEndedDay,
			};
		}
		case actionTypes.USER_HAS_ENDED_DAY: {
			return {
				...state,
				userHasEndedDay: action?.data,
			};
		}
		case actionTypes.GET_NEWEST_VERSION: {
			return {
				...state,
				version: action?.data,
			};
		}
		case actionTypes.CUSTOMER_CREATE:
		case actionTypes.CREATE_ISSUE_FORM:
		case actionTypes.CREATE_OR_UPDATE_PROJECT: {
			return {
				...state,
				customerFiltersNeedReset: state?.customerFiltersNeedReset + 1,
			};
		}
		case actionTypes.FETCH_SHARED_PASSWORD_POLICIES: {
			return {
				...state,
				sharedPasswordPolicies: action?.policies,
			};
		}
		case actionTypes.USER_MUST_CHANGE_PASS: {
			return {
				...state,
				mustChangePassword: action?.data?.MustChange,
			};
		}
		default: {
			return state;
		}
	}
};

export default sessionReducer;

import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	swimlaneSpeedDial: {
		transform: 'rotate(0deg)',
		width: '24px',
		height: '24px',
		display: 'flex',
		padding: '3px',
	},
	swimlanesContainer: 'swimlanesContainer',
	swimlanesId: 'swimlane:id:',
	jumpToSwimlane: 'jumpToSwimlane',
	smoothBehavior: 'smooth' as const,
	menuId: 'account-menu',
	...GlobalResources,
};

export const Translation = {
	pl: {
		Kanban: {
			KanbanBoard: {
				KanbanContent: {
					ColumnsKanbanContent: {
						SwimlaneConfiguration: {
							swimlaneMenu: 'Menu swimeline',
							swimlaneConfiguration: 'Konfiguracja swimlane',
							swimlaneCollapseIn: 'Zwiń wszystkie swimeliny',
							swimlaneCollapseOut: 'Rozwiń wszystkie swimeliny',
							swimlaneBarCollapseIn: 'Zwiń pasek swimlane',
							swimlaneBarCollapseOut: 'Rozwiń pasek swimlane',
							scrollToSwimeline: 'Przewiń do swimelina',
						},
					},
				},
			},
		},
	},
	en: {
		Kanban: {
			KanbanBoard: {
				KanbanContent: {
					ColumnsKanbanContent: {
						SwimlaneConfiguration: {
							swimlaneMenu: 'Swimeline menu ',
							swimlaneConfiguration: 'Swimeline configuration',
							swimlaneCollapseIn: 'Collapse all swimelines',
							swimlaneCollapseOut: 'Expand all swimelines',
							swimlaneBarCollapseIn: 'Shrink swimlane bar',
							swimlaneBarCollapseOut: 'Expand swimlane bar',
							scrollToSwimeline: 'Scroll to swimeline',
						},
					},
				},
			},
		},
	},
};

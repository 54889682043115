import { AxiosResponse } from 'axios';
import clsx from 'clsx';
import { useSnackbar } from 'notistack';
import React, { createContext, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import { Container, Typography } from '@mui/material';

import {
	DeepPartial,
	EAnswer,
	EFieldDefinitionType,
	FullFilters,
	IConfirmationMessageData,
	IErrorValidationV2,
	IIssue,
	IIssueIssueModal,
	IIssueKanbanDetails,
	ITaskerUser,
} from '@skillandchill/tasker-types';
import { ConfirmationMessage, SnackbarErrorList } from '@skillandchill/tasker-widgets2';
import { errorVariant, ModalsEvent, useOpenContext } from '@skillandchill/tasker-widgets2/dist/utils';
import Modal from '@skillandchill/tasker-widgets2/dist/widgets/Modal';
import { useModal } from '@skillandchill/tasker-widgets2/dist/widgets/Modal/useModal';
import Popup from '@skillandchill/tasker-widgets2/dist/widgets/Popup';

import {
	deleteIssueFromKanban,
	get_IssueFormByIssueProjectTypeId,
	get_projectIssueTypeByProject,
	issue_delete,
	issue_fetchOneNew,
	issue_modal_create,
	issue_modal_reset,
	issue_modal_update,
	issue_restore,
	issueModal_fetchBasic,
	sessionRecalculate,
} from '@/store/actions';
import { IErrorV3 } from '@/store/ITypes';
import { IReducerState } from '@/store/reducers/types';
import { Dispatch } from '@/store/types';
import { useQuery, useTrans, ModalType, successVariant } from '@/utils';

import { KeyboardSupport, useInitialLoad } from './hooks';
import { IssueModalActions } from './IssueModalActions';
import { IssueModalContent } from './IssueModalContent';
import { EIssueModalDetailsPageFields, IIssueModalData } from './IssueModalContent/IssueDetailsPage/model';
import { IssueModalTitle } from './IssueModalTitle';
import { IFieldValuesChange, IIssueModalDataContext } from './modal';
import { defaultConfirmationMessageData, Resources } from './resources';
import { useStyles } from './styles';
import { formatErrors, getChangesInUsers, mapFieldValueToDelete, mapInitialStateToIssueState } from './utils';

export const issueModalDataContext = createContext<IIssueModalDataContext>({} as IIssueModalDataContext);

export const IssueModal = () => {
	const classes = useStyles();
	const query = useQuery();

	const history = useHistory();
	const dispatch: Dispatch = useDispatch();
	const snackbar = useSnackbar();
	useInitialLoad({ onError: handleCloseModal, onClose: handleCloseModal });
	const confirmationMessageContext = useOpenContext();

	const { t } = useTrans('IssueModal');

	const issueId = useMemo(() => parseInt(query['issueId']), [query['issueId']]);
	const projectId = useMemo(() => parseInt(query['projectId']), [query['projectId']]);

	const context = useModal(ModalType.Issue);
	const contextKanbanConfirmation = useOpenContext();
	KeyboardSupport({ handleClose: handleCloseModal });

	const [confirmationMessageData, setConfirmationMessageData] = useState<IConfirmationMessageData>(
		defaultConfirmationMessageData
	);

	const filtersNew: FullFilters = useSelector((state: IReducerState) => state?.IssueModalEditor?.filterIssue);
	const issue = useSelector((state: IReducerState) => state?.IssueModalEditor?.issueModal);
	const taskerUser: Partial<ITaskerUser> = useSelector((state: IReducerState) => state?.Session?.taskerUser);
	const kanbanDetails: IIssueKanbanDetails = useSelector(
		(state: IReducerState) => state?.IssueModalEditor?.issueModalKanbanDetails
	);

	const [fieldValues, setFieldValues] = useState<IFieldValuesChange>({
		fieldsValueToCreate: [],
	});
	const [issueState, setIssueState] = useState<IIssueModalData | null>(null);
	const [inputsInEdit, setInputsInEdit] = useState<
		Record<EIssueModalDetailsPageFields, boolean> | Record<string, unknown>
	>({});
	const [issueErrors, setIssueErrors] = useState<IErrorValidationV2<EIssueModalDetailsPageFields>[]>([]);

	const isEdittingIssue = useMemo(() => !(!Number.isInteger(issueId) || Number.isNaN(issueId) || !issueId), [
		issueId,
	]);

	useEffect(() => {
		if (isEdittingIssue) return;

		setInputsInEdit({
			Assigned: true,
			Auditor: true,
			DescriptionHtml: true,
			Observer: true,
			ProjectId: true,
			ProjectIssueTypeId: true,
			Reporter: true,
			Title: true,
		});
	}, [isEdittingIssue, issueId]);

	function handleCloseModal() {
		const path = history?.location?.pathname;
		setIssueErrors([]);
		setIssueState(null);
		setFieldValues({});
		history.push(path.replace(/issueId=[0-9]*/, ''));
		context.close();

		dispatch(issue_modal_reset());
	}

	useEffect(() => {
		const issueIdCondition = typeof issueId === 'number' && issueId === 0;
		const issueCondition = !Number.isNaN(issue?.Id) && (issue?.Id ?? 0) > 0 && !Number.isNaN(issueId);

		if (issueIdCondition || issueCondition) {
			context.open();
			dispatch(sessionRecalculate());
		}
	}, [issue?.Id, issueId]);

	useEffect(() => {
		if (!issueState?.ProjectId) return;
		dispatch(get_projectIssueTypeByProject(issueState?.ProjectId));
	}, [issueState?.ProjectId, projectId]);

	useEffect(() => {
		if (
			!issueState?.ProjectIssueTypeId ||
			!context.isOpen ||
			((Number.isNaN(issueId) || !issueId) && isEdittingIssue)
		)
			return;

		dispatch(get_IssueFormByIssueProjectTypeId(issueState.ProjectIssueTypeId as number, Number(issueId)));
	}, [issueState?.ProjectIssueTypeId, issueId, context.isOpen]);

	useEffect(() => {
		const isCreatingNewIssue = issueId === 0;

		if (isCreatingNewIssue) {
			const state: IIssueModalData = {
				...mapInitialStateToIssueState({}),
				Reporter: [taskerUser],
			};

			if (projectId) state.ProjectId = projectId;

			setIssueState(state);
			return;
		}

		setIssueState(mapInitialStateToIssueState(issue));
	}, [issue, issueId, projectId]);

	useEffect(() => {
		if (!issueId) return;
		setFieldValues({});
		setIssueState(null);
		setInputsInEdit({});
	}, [issueId]);

	const handleProjectChange = (projectId: number) => {
		setIssueState(
			prevState =>
				({
					...prevState,
					ProjectIssueTypeId: null,
					ProjectId: projectId,
				} as IIssueModalData)
		);

		setFieldValues({});
		setInputsInEdit(prevState => ({
			...prevState,
			ProjectIssueTypeId: true,
			Properties: true,
		}));
	};

	const handleIssueTypeChange = () => {
		setFieldValues({});
		setInputsInEdit(prevState => ({
			...prevState,
			Properties: true,
		}));
		setIssueErrors(prevState =>
			prevState.filter(error => error.inputName !== EIssueModalDetailsPageFields.properties)
		);
	};

	const handleEditStateChange = (inputName: EIssueModalDetailsPageFields, val: boolean) => {
		setInputsInEdit(prevState => ({
			...prevState,
			[inputName]: val,
		}));
	};
	const handleInputChange = (inputName: EIssueModalDetailsPageFields, value: any) => {
		setIssueState(prevState => {
			const isClearFormProperties =
				inputName === EIssueModalDetailsPageFields.projectId ||
				inputName === EIssueModalDetailsPageFields.projectIssueTypeId;

			const properties = isClearFormProperties
				? {
						Properties: {
							[EFieldDefinitionType.Boolean]: {},
							[EFieldDefinitionType.Date]: {},
							[EFieldDefinitionType.Decimal]: {},
							[EFieldDefinitionType.Integer]: {},
							[EFieldDefinitionType.Text]: {},
							[EFieldDefinitionType.TagGroup]: {},
							[EFieldDefinitionType.DateTime]: {},
							[EFieldDefinitionType.Dictionary]: {},
							[EFieldDefinitionType.TextArea]: {},
						},
				  }
				: issueState?.Properties;

			return {
				...prevState,
				[inputName]: value,
				Properties: properties,
			} as IIssueModalData;
		});
		setIssueErrors(prevState => prevState.filter(error => error.inputName !== inputName));
	};

	const handleReset = (inputName: EIssueModalDetailsPageFields) => {
		if (inputName === EIssueModalDetailsPageFields.assigned) {
			setIssueState(prevState => ({ ...prevState, Assigned: issue?.Members?.Assigned ?? [] } as IIssueModalData));
			setInputsInEdit(prevState => ({
				...prevState,
				Assigned: false,
			}));
			return;
		}
		if (inputName === EIssueModalDetailsPageFields.auditor) {
			setIssueState(prevState => ({ ...prevState, Auditor: issue?.Members?.Auditor ?? [] } as IIssueModalData));
			setInputsInEdit(prevState => ({
				...prevState,
				Auditor: false,
			}));
			return;
		}
		if (inputName === EIssueModalDetailsPageFields.observer) {
			setIssueState(prevState => ({ ...prevState, Observer: issue?.Members?.Observer ?? [] } as IIssueModalData));
			setInputsInEdit(prevState => ({
				...prevState,
				Observer: false,
			}));
			return;
		}
		if (inputName === EIssueModalDetailsPageFields.reporter) {
			setIssueState(prevState => ({ ...prevState, Reporter: issue?.Members?.Reporter ?? [] } as IIssueModalData));
			setInputsInEdit(prevState => ({
				...prevState,
				Reporter: false,
			}));
			return;
		}

		if (
			[
				EIssueModalDetailsPageFields.projectId,
				EIssueModalDetailsPageFields.projectIssueTypeId,
				EIssueModalDetailsPageFields.properties,
			].includes(inputName)
		) {
			setFieldValues({});
			setIssueState(
				prevState =>
					({
						...prevState,
						ProjectId: issue?.ProjectId ?? null,
						ProjectIssueTypeId: issue?.ProjectIssueTypeId ?? null,
					} as IIssueModalData)
			);

			setInputsInEdit(prevState => ({
				...prevState,
				ProjectId: false,
				ProjectIssueTypeId: false,
				Properties: false,
			}));
			return;
		}

		if (issue) {
			setIssueState(
				prevState => ({ ...prevState, [inputName]: issue[inputName as keyof IIssue] } as IIssueModalData)
			);
			setInputsInEdit(prevState => ({
				...prevState,
				[inputName]: false,
			}));
		}
	};

	const updateIssue = async (): Promise<void> => {
		const issueData = {
			Id: issue.Id,
			...issueState,
			IssueUsersChanges: {
				Assigned: getChangesInUsers(issue?.Members?.Assigned ?? [], issueState?.Assigned ?? []),
				Auditor: getChangesInUsers(issue?.Members?.Auditor ?? [], issueState?.Auditor ?? []),
				Observer: getChangesInUsers(issue?.Members?.Observer ?? [], issueState?.Observer ?? []),
				Reporter: getChangesInUsers(issue?.Members?.Reporter ?? [], issueState?.Reporter ?? []),
			},
		} as Partial<IIssue>;

		const kanbanDetailsData = {
			isKanban: kanbanDetails?.isKanban,
			columnTagId: kanbanDetails?.columnTagId,
			kanbanId: kanbanDetails?.kanbanId,
			swimlaneId: kanbanDetails?.swimlaneId,
		};

		await dispatch(
			issue_modal_update(
				filtersNew,
				0,
				8,
				issueData,
				fieldValues?.fieldsValueToCreate,
				fieldValues?.fieldsValueToUpdate,
				fieldValues?.fieldsValueToDelete?.map(mapFieldValueToDelete),
				kanbanDetailsData
			)
		)
			.then(() => snackbar.enqueueSnackbar('Update issue', successVariant))
			.catch((error: IErrorV3) => setIssueErrors(formatErrors(error)));
	};

	const handleCreateIssue = (closeModal = true): Promise<AxiosResponse<DeepPartial<IIssueIssueModal>>> => {
		return (dispatch(
			issue_modal_create(
				filtersNew,
				0,
				10,
				{
					...issueState,
					IssueUsersChanges: {
						Assigned: getChangesInUsers(issue?.Members?.Assigned ?? [], issueState?.Assigned ?? []),
						Auditor: getChangesInUsers(issue?.Members?.Auditor ?? [], issueState?.Auditor ?? []),
						Observer: getChangesInUsers(issue?.Members?.Observer ?? [], issueState?.Observer ?? []),
						Reporter: getChangesInUsers(issue?.Members?.Reporter ?? [], issueState?.Reporter ?? []),
					},
				} as Partial<IIssue>,
				undefined,
				[],
				(issueState?.Attachment?.map(file => file?.File) as File[]) ?? [],
				undefined,
				issueState?.SubTasks?.map(issue => issue?.Id),
				issueState?.ParentTasks?.map(issue => issue?.Id),
				fieldValues?.fieldsValueToCreate ?? [],
				undefined,
				{
					isKanban: kanbanDetails?.isKanban,
					columnTagId: findCorrectColumnTagId(),
					kanbanId: kanbanDetails?.kanbanId,
					swimlaneId: kanbanDetails?.swimlaneId,
				}
			)
		)
			.then(() => {
				snackbar.enqueueSnackbar(t('updateIssueSnackbarSuccess'), successVariant);

				if (closeModal) handleCloseModal();
				else {
					dispatch(issueModal_fetchBasic());
					setIssueState(null);
				}
			})
			.catch(error => {
				if (error?.response?.data?.message?.some((x: any) => x.inputName == 'mapTagToFieldDefinition')) {
					handleCloseModal();
					contextKanbanConfirmation.open();
				}
				setIssueErrors(
					error?.response?.data?.message?.map((error: any) => ({
						...error,
						inputName: error?.inputName?.replace('issue.', ''),
					})) ?? []
				);
			}) as unknown) as Promise<AxiosResponse<DeepPartial<IIssueIssueModal>>>;
	};

	function handleDeleteIssue() {
		if (!issue?.Id) return;
		const id = issue?.Id;
		dispatch(issue_delete(id, kanbanDetails?.swimlaneId))
			.then(() => {
				if (kanbanDetails?.kanbanId && kanbanDetails?.swimlaneId)
					dispatch(deleteIssueFromKanban(id ?? 0, kanbanDetails?.swimlaneId, kanbanDetails?.columnTagId));
				handleCloseModal();
				snackbar.enqueueSnackbar(t('deleteIssueSnackbarSuccess'), successVariant);
			})
			.catch(errors => {
				if (errors?.respone?.data?.message) {
					snackbar.enqueueSnackbar(
						<SnackbarErrorList errors={errors?.response?.data?.message ?? []} />,
						errorVariant
					);
				}
			});
	}

	const handleDeleteIssueWithConfirmation = () => {
		const handleConfirmationOnDeleteManyAgreements = (x: EAnswer) => {
			if (x === EAnswer.YES) handleDeleteIssue();
		};

		confirmationMessageContext.once(ModalsEvent.Close, handleConfirmationOnDeleteManyAgreements);
		confirmationMessageContext.open();
		setConfirmationMessageData({
			title: t('confirmationTitle'),
			submitText: t('confirmationSubmitText'),
			cancelText: t('confirmationCancelText'),
		});
	};

	const handleRestore = () => {
		if (!issue?.Id) return;
		const id = issue?.Id;
		dispatch(issue_restore(id));
	};

	function findCorrectColumnTagId() {
		const statusField = fieldValues?.fieldsValueToCreate?.find(
			fieldValue => fieldValue?.AttributeDefinition?.IsStatus
		);

		return (
			kanbanDetails?.mappedTags?.find(
				find =>
					find?.FieldDefinitionId == statusField?.AttributeDefinitionId &&
					find?.FieldDefinitionTagId === statusField?.ValueTagId
			)?.TagId ?? kanbanDetails?.columnTagId
		);
	}

	const handleSave = async (closeModal = true): Promise<void> => {
		if (!issueId) {
			const issueData = await handleCreateIssue(closeModal);

			if (closeModal) {
				handleCloseModal();
				return;
			}

			setInputsInEdit({});
			setIssueState(null);
			setFieldValues({});
			setIssueErrors([]);
			history.push(`${history?.location?.pathname}?issueId=${issueData?.data?.Issue?.Id}`);
		} else {
			await updateIssue();
			if (closeModal) {
				handleCloseModal();
				return;
			}
			setInputsInEdit({});
			setIssueState(null);
			setFieldValues({});
			setIssueErrors([]);
			const issueIdFormatted = Number(issueId);
			if (issueIdFormatted > 0) await dispatch(issue_fetchOneNew(issueIdFormatted));
			return;
		}
	};

	return (
		<>
			<issueModalDataContext.Provider
				value={{
					issueState,
					inputsInEdit,
					handleEditStateChange,
					handleInputChange,
					setFieldValues,
					fieldValues,
					handleReset,
					handleProjectChange,
					handleIssueTypeChange,
					issueErrors,
					isEdittingIssue,
					setIssueErrors,
					setIssueState,
				}}
			>
				<Modal
					context={context}
					title={
						<IssueModalTitle
							handleCloseModal={handleCloseModal}
							handleDeleteIssueWithConfirmation={handleDeleteIssueWithConfirmation}
						/>
					}
					actions={
						<IssueModalActions
							handleSave={handleSave}
							handleCloseModal={handleCloseModal}
							handleDeleteIssueWithConfirmation={handleDeleteIssueWithConfirmation}
							handleRestore={handleRestore}
						/>
					}
					className={classes.modal}
				>
					<Container className={clsx(classes.container, classes.fullHeight)}>
						<IssueModalContent />
					</Container>
				</Modal>

				<ConfirmationMessage
					popupContext={confirmationMessageContext}
					submitButtonText={confirmationMessageData.submitText}
					cancelButtonText={confirmationMessageData.cancelText}
					title={confirmationMessageData.title}
				/>
			</issueModalDataContext.Provider>
			<Popup
				closeOnBlur
				context={contextKanbanConfirmation}
				content={
					<div className={classes.kanbanPopupContent}>
						<Typography className={classes.marginBottomS} variant={Resources.GlobalResources.h2}>
							{t('kanbanCreationError')}
						</Typography>
						<Typography variant={Resources.GlobalResources.h6}>
							{t('kanbanCreationErrorSubtitle1')}
						</Typography>
						<Typography variant={Resources.GlobalResources.h6}>
							{t('kanbanCreationErrorSubtitle2')}
						</Typography>
					</div>
				}
			/>
		</>
	);
};

import * as Joi from 'joi';
import { Dispatch, SetStateAction } from 'react';

import { EDropzoneType, IDropzoneAttachment } from '../model';

export interface Props<T extends IDropzoneAttachment> {
	baseProps: IDropzoneAttachmentRecordBaseProps<T>;
	handlers: IDropzoneAttachmentRecordDropHandlers<T> | IDropzoneAttachmentRecordAttachmentFormHandlers<T>;
	classes?: {
		tile?: string;
		icon?: string;
		iconButton?: string;
	};
}

export interface IDropzoneAttachmentRecordBaseProps<T> {
	index: number;
	attachment: T;
	setAttachments: Dispatch<SetStateAction<T[]>>;
	isEdit: boolean;
	isDisabled?: boolean;
	setIsHovered: Dispatch<SetStateAction<boolean>>;
}

export interface IDropzoneAttachmentRecordBaseHandlers<T> {
	getFilename: (attachment: T) => string;
	getAttachmentId: (attachment: T) => number;
	onRemove: (attachment: T) => void;
}

export interface IDropzoneAttachmentRecordDropHandlers<T> extends IDropzoneAttachmentRecordBaseHandlers<T> {
	type?: EDropzoneType.Drop;
}

export interface IDropzoneAttachmentRecordAttachmentFormHandlers<T> extends IDropzoneAttachmentRecordBaseHandlers<T> {
	type?: EDropzoneType.AttachmentForm;
	renderForm: (attachment: T, index: number) => React.ReactNode;
	renderFormInfo: (attachment: T, index: number) => React.ReactNode;
	onFormSubmit: (attachment: T, index: number, setUploadProgress: Dispatch<SetStateAction<number>>) => void;
	onFormValidate: (attachment: T) => Joi.ValidationError | undefined;
}

export enum EDropzoneFileStatus {
	Pending = 1,
	Waiting = 2,
	Downloading = 3,
	Uploading = 4,
	Uploaded = 5,
	Downloaded = 6,
	Saved = 7,
	Failed = 8,
}

import React, { useContext, useEffect, useState } from 'react';

import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';

import { EIssueModalTabs } from '@skillandchill/tasker-types';

import { useTrans } from '@/utils/hooks/useTrans';

import { issueModalDataContext } from '../..';

import { Props, ITab } from './model';
import { Resources } from './resources';
import { useStyles } from './styles';
import { getClasses, getTabs } from './utils';

export const Tabs = (props: Props) => {
	const { selectedTab, setSelectedTab } = props;
	const classes = useStyles();
	const { t } = useTrans('IssueModal.IssueModalContent.Tabs');
	const { isEdittingIssue } = useContext(issueModalDataContext);

	const [tabs, setState] = useState<ITab[]>(getTabs(classes));

	useEffect(() => {
		setSelectedTab(EIssueModalTabs.details);
	}, []);

	useEffect(() => {
		setState(getTabs(classes, isEdittingIssue === false ? true : undefined));
	}, [t, isEdittingIssue]);

	const handleChange = (event: React.SyntheticEvent, newTab: EIssueModalTabs) => {
		setSelectedTab(newTab);
	};

	return (
		<TabContext value={selectedTab}>
			<Box sx={{ borderBottom: 1, borderColor: Resources.divider }}>
				<TabList
					onChange={handleChange}
					className={classes.tabList}
					textColor={Resources.GlobalResources.inherit}
				>
					{tabs?.map((tab: ITab, index: number) => (
						<Tab
							key={Resources.getKey(index)}
							className={getClasses(tab?.value, classes, selectedTab)}
							label={
								<div className={classes.flexYCenter}>
									{tab?.icon}
									<span>{tab?.label}</span>
								</div>
							}
							value={tab?.value}
							disabled={tab?.disableNew === true && isEdittingIssue === false}
						/>
					))}
				</TabList>
			</Box>
		</TabContext>
	);
};

import Axios, { CancelTokenSource } from 'axios';

import { AsyncAction } from '@skillchill/redux-promisify';

import {
	IChangeEmail,
	IChangeLogin,
	IChangePassword,
	IOrganization,
	IPage,
	ITaskerUser,
	ITaskerUserWithRole,
} from '@skillandchill/tasker-types';

import { IJson } from '@/store/ITypes';
import { Action } from '@/store/types';
import axios from '@/utils/axios';

import { ActionType } from '../types';

export const USERS_FETCH_PAGE: ActionType = 'USERS_FETCH_PAGE';
export const USER_FETCH_ONE: ActionType = 'USER_FETCH_ONE';
export const FETCH_ALL_USERS: ActionType = 'FETCH_ALL_USERS';
export const USER_FETCH_NEW: ActionType = 'USER_FETCH_NEW';
export const USER_FETCH_VALIDATION_DATA: ActionType = 'USER_FETCH_VALIDATION_DATA';
export const USER_UPDATE_SESSION: ActionType = 'USER_UPDATE_SESSION';
export const TIME_REPORT_SET_LOCAL_USERS: ActionType = 'TIME_REPORT_SET_LOCAL_USERS';
export const TASKER_USER_ALL: ActionType = 'TASKER_USER_ALL';
export const WORK_DAY_END_SUMMARY_SET_LOCAL_USERS: ActionType = 'WORK_DAY_END_SET_LOCAL_USERS';

const basePath = 'TaskerUser';
const authPath = 'auth';

function _fetchUserPage(taskerUsers: IPage<Partial<ITaskerUser>[]>): Action {
	return {
		type: USERS_FETCH_PAGE,
		taskerUsers,
	};
}
function _fetchValidationUsers(taskerUsers: Partial<ITaskerUser>[]): Action {
	return {
		type: USER_FETCH_VALIDATION_DATA,
		taskerUsers,
	};
}

function _fetchOneUser(taskerUser: Partial<ITaskerUser>): Action {
	return {
		type: USER_FETCH_ONE,
		taskerUser,
	};
}

function _fetchAllUsers(taskerUsers: Partial<ITaskerUser>[]): Action {
	return {
		type: FETCH_ALL_USERS,
		taskerUsers,
	};
}

function _updateUserSession(taskerUser: Partial<ITaskerUser>): Action {
	return {
		type: USER_UPDATE_SESSION,
		taskerUser,
	};
}

function _getAllTaskerUser(organization: Partial<IOrganization>[]): Action {
	return {
		type: TASKER_USER_ALL,
		data: organization,
	};
}

export function fetch_taskerUser_page(from = 0, count = 100, text = ''): AsyncAction {
	return function(context, dispatch) {
		context.then(json => dispatch(_fetchUserPage((json as IJson).data)));
		return axios().post(`${basePath}/from/${from}/count/${count}/name`, { text });
	};
}
export function fetch_Validation_users(): AsyncAction {
	return function(context, dispatch) {
		context.then(json => dispatch(_fetchValidationUsers((json as IJson).data)));
		return axios().get(`${basePath}/validation`);
	};
}

let fetch_all_taskerUsersSource: CancelTokenSource | undefined;
export function fetch_all_taskerUsers(): AsyncAction {
	if (fetch_all_taskerUsersSource) fetch_all_taskerUsersSource.cancel();
	fetch_all_taskerUsersSource = Axios.CancelToken.source();
	return function(context, dispatch) {
		context.then(json => dispatch(_fetchAllUsers((json as IJson).data)));
		return axios().get(basePath);
	};
}

export function fetch_all_active_taskerUsers(): AsyncAction {
	return function(context, dispatch) {
		context.then(json => dispatch(_fetchAllUsers((json as IJson).data)));
		return axios().get(`${basePath}/active`);
	};
}

export function fetch_all_active_taskerUsersWithRoles(): AsyncAction {
	return function(context, dispatch) {
		context.then(json => dispatch(_fetchAllUsers((json as IJson).data)));
		return axios().get(`${basePath}/activeWithRoles`);
	};
}

export function fetch_all_active_taskerUsersKanban(projectIds: number[]): AsyncAction {
	return function(context, dispatch) {
		context.then(json => dispatch(_fetchAllUsers((json as IJson).data)));
		return axios().post(`${basePath}/Portal/Kanban`, { ProjectIds: projectIds });
	};
}

export function fetch_taskerUser(id: number): AsyncAction {
	return function(context, dispatch) {
		context.then(json => dispatch(_fetchOneUser((json as IJson).data)));
		return axios().get(`${basePath}/${id}`);
	};
}

export function update_taskerUser(id: number, data: ITaskerUserWithRole): AsyncAction {
	return function(context, dispatch) {
		context.then(json => dispatch(_fetchUserPage((json as IJson).data)));
		return axios().patch(`${basePath}/${id}`, data);
	};
}

export function update_taskerUserNew(id: number, data: Partial<ITaskerUser>): AsyncAction {
	return function(context, dispatch) {
		context.then(() => dispatch(_updateUserSession(data)));
		return axios().patch(`${basePath}/me/${id}`, data);
	};
}

export function create_TaskerUser(data: ITaskerUserWithRole): AsyncAction {
	return function() {
		return axios().post(basePath, data);
	};
}

export function changePassword_taskerUser(data: IChangePassword): AsyncAction {
	return function() {
		return axios().post(`${basePath}/changePassword`, data);
	};
}

export function changeLogin_taskerUser(data: IChangeLogin): AsyncAction {
	return function() {
		return axios().post(`${basePath}/changeLogin`, data);
	};
}

export function changeEmail_taskerUser(data: IChangeEmail): AsyncAction {
	return function() {
		return axios().post(`${basePath}/changeEmail`, data);
	};
}

export function firstChangePassword_taskerUser(passwords: IChangePassword, uuid: string): AsyncAction {
	return function() {
		return axios().post(`${authPath}/firstChangePassword`, { passwords, uuid });
	};
}

export function timeRaport_setTaskerUser(user: Partial<ITaskerUser>): Action {
	return {
		type: TIME_REPORT_SET_LOCAL_USERS,
		data: user,
	};
}

export function get_allTaskerUsers(): AsyncAction {
	return function(context, dispatch) {
		context.then(json => dispatch(_getAllTaskerUser((json as IJson).data)));
		return axios().get(`${basePath}/All/Users/Organizations`);
	};
}

export function workDayEndSummary_setTaskerUser(user: Partial<ITaskerUser>): Action {
	return {
		type: WORK_DAY_END_SUMMARY_SET_LOCAL_USERS,
		data: user,
	};
}

export function changePassword_taskerUser_AUTH(passwords: IChangePassword): AsyncAction {
	return function() {
		return axios().post(`${basePath}/firstChangePassword`, { passwords });
	};
}

import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { getGlobalStyles } from '@skillandchill/tasker-widgets2/dist/utils/assets/GlobalStyles/styles';

import { StyleProps } from './model';

export const useStyles = makeStyles<Theme, StyleProps>(
	() => ({
		...getGlobalStyles(),
		placeholderHeightSetter: {
			'&.height-preserving-container:empty': {
				minHeight: 'calc(var(--child-height))',
				boxSizing: 'border-box',
			},

			'--child-height': props => `${props.size}px`,
		},
	}),
	{ name: `KanbanBoard_KanbanContent_ColumnsKanbanContent_Swimlane_TaskList_HeightPreservingItem` }
);

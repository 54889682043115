import { t } from 'i18next';

import { CommonResources as GlobalResources } from '@skillandchill/tasker-widgets2/dist/utils/language/globalResources';

export const Resources = {
	rtl: 'rtl',
	ltr: 'ltr',
	...GlobalResources,
};

export const Translation = {
	pl: {
		Kanban: {
			KanbanBoard: {
				KanbanContent: {
					EditKanbanContent: {
						Tabs: {
							General: 'Główne',
							Mappings: 'Mapowania',
							Swimlanes: `Swimlane'y`,
						},
					},
				},
			},
		},
	},
	en: {
		Kanban: {
			KanbanBoard: {
				KanbanContent: {
					EditKanbanContent: {
						Tabs: {
							General: 'General',
							Mappings: 'Mappings',
							Swimlanes: 'Swimlanes',
						},
					},
				},
			},
		},
	},
};

export const getTabs = () => [
	{
		value: EKanbanConfigurationTabs.General,
		label: t('Kanban.KanbanBoard.KanbanContent.EditKanbanContent.Tabs.General'),
	},
	{
		value: EKanbanConfigurationTabs.Swimlanes,
		label: t('Kanban.KanbanBoard.KanbanContent.EditKanbanContent.Tabs.Swimlanes'),
	},
	{
		value: EKanbanConfigurationTabs.Mappings,
		label: t('Kanban.KanbanBoard.KanbanContent.EditKanbanContent.Tabs.Mappings'),
	},
];
export enum EKanbanConfigurationTabs {
	General = 'General',
	Swimlanes = 'Swimlanes',
	Mappings = 'Mappings',
}
